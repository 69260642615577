import React from 'react'
import Sidebar from '../components/mainpage/subcomponent/Sidebar'
import '../components/styles/dashboard.css'
import Profile from '../components/mainpage/subcomponent/Profile'

const Mainprofile = () => {
    return (
        <div>
            <Sidebar />
            <div className='container-fluid'>
                <Profile />
            </div>
        </div>
    )
}

export default Mainprofile