import React from 'react'
import '../../styles/dashboard.css';
import { Table } from 'react-bootstrap';

const TermsConditionTable = ({ termsAndConditions }) => {
    return (
        <>
            <Table className='table-backgorund'>
                <thead className='table-head'>
                    <tr>
                        <th>FINANCE AMOUNT</th>
                        <th>DOWN PAYMENT </th>
                        <th>ADVANCE PAYMENTS</th>
                        <th>TERM</th>
                        <th>MONTHLY PAYMENT</th>
                        <th>TOTAL DUE UPFRONT</th>
                    </tr>
                </thead>
                <tbody>
                        <div style={{ marginBottom: "0.5rem" }}></div>
                        <tr className='deal'>
                            <td>{termsAndConditions?.finance_amount}</td>
                            <td>{termsAndConditions?.down_payment_percentage}</td>
                            <td>{termsAndConditions?.advance_payment}</td>
                            <td>{termsAndConditions?.term}</td>
                            <td>{termsAndConditions?.monthly_payment}</td>
                            <td>{termsAndConditions?.total_out_of_pocket}</td>

                        </tr>
                </tbody>
            </Table>
        </>
    )
}

export default TermsConditionTable