import {
  APPLICATION_UUID,
  INFORMATION_SLUG,
  IS_LOGGED_IN,
  PREQUALIFIED_OFFERS,
  QUESTIONS,
  REFRESH_APPLICATION_LINK,
  REFRESH_PROFILE,
  USER_INFO,
  SIGNUP_RES,
  REFRESH_APPLICATIONS,
  QUESTION_BUSINESS,
  QUESTION_PERSONAL,
  IS_PERSONAL_FLOW,
} from "./ActionConstants";

export const reducer = (state, action) => {
  switch (action.type) {
    case USER_INFO:
      return { ...state, user: action.payload };
    case QUESTIONS:
      return { ...state, questions: action.payload };
    case QUESTION_BUSINESS:
      return {
        ...state,
        questions: { ...state.questions, business: action.payload },
      };
    case QUESTION_PERSONAL:
      return {
        ...state,
        questions: { ...state.questions, personal: action.payload },
      };
    case REFRESH_APPLICATION_LINK:
      return { ...state, refreshApplicationLink: action.payload };
    case INFORMATION_SLUG:
      return { ...state, informationSlug: action.payload };
    case APPLICATION_UUID:
      return { ...state, applicationUUID: action.payload };
    case PREQUALIFIED_OFFERS:
      return { ...state, prequalifiedOffers: action.payload };
    case IS_LOGGED_IN:
      return { ...state, isLoggedIn: action.payload };
    case REFRESH_PROFILE:
      return { ...state, refreshProfile: action.payload };
    case SIGNUP_RES:
      return { ...state, signupResponse: action.payload };
    case REFRESH_APPLICATIONS:
      return { ...state, refreshApplications: action.payload };
    case IS_PERSONAL_FLOW:
      return { ...state, isPersonalFlow: action.payload };
    default:
      return state;
  }
};
