import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/CloseButton";
import AddNewUser from "../../components/mainpage/subcomponent/AddNewUser";
const AddUserModal = ({ showModal, setShowModal }) => {
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="modal-width"
        centered
        animation={true}
      >
        <Modal.Body>
          <div style={{ float: "right", fontSize: "20px" }}>
            <CloseButton onClick={handleCloseModal} variant="dark" />
          </div>
          <AddNewUser handleCloseModal={handleCloseModal} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddUserModal;
