import React, { useContext } from 'react'
import { useState } from 'react'
import { Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import axios, { axiosPrivate } from '../../../../api/axios';
import Cookies from 'universal-cookie';
import { useEffect } from 'react';
import '../../../styles/dashboard.css'
import { Checkbox, FormControlLabel } from '@mui/material';
import { GlobalContext } from '../../../../context/GlobalContext';
import ColorPicker from '../../../../utils/ColorPicker';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
const cookies = new Cookies();

const EditCompanyInfo = ({ handleClose }) => {
    const { state } = useContext(GlobalContext);
    const [showAlert, setShowAlert] = useState('')
    const [errMsg, setErrMsg] = useState('');
    const [showLoader] = useState(false);
    const [validated, setValidated] = useState(false);
    const [imageFile, setImageFile] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [color, setColor] = useState(state?.user?.themeColor);
    const options = ['Construction', 'Ag', 'Transpo', 'Lifts'];
    const [companyData, setCompanyData] = useState({
        name: "",
        email: "",
        contact_number: "",
        address: "",
        logo: "",
        theme_color: "",
        is_active: false,
        equipment_category: ""
    })
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleClose2 = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
        setOpen2(false)
    };
    useEffect(() => {
        setCompanyData((prevFormData) => ({
            ...prevFormData,
            theme_color: color,
        }))
    }, [color])
    useEffect(() => {
        axiosPrivate.get("lender/admin/list/company-details/", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${cookies.get("access")}`,
            },
            withCredentials: true,
        }).then((response) => {
            const responseData = response.data;
            setCompanyData({
                name: responseData?.name ?? "",
                email: responseData?.email ?? "",
                contact_number: formatNumber(responseData?.contact_number) ?? "",
                address: responseData?.address ?? "",
                theme_color: responseData?.theme_color,
                logo: responseData?.logo ?? "",
                is_active: responseData?.is_active ?? false,
                equipment_category: responseData?.equipment_category
            });
            setImageURL(responseData?.logo)
            setColor(responseData?.theme_color)
        })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSignup = (e) => {
        setErrMsg('');
        setShowAlert('');
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true)
        } else {
            setValidated(false)
            let body = { ...companyData }
            body.contact_number = companyData.contact_number.replace(/-/g, '')
            body.logo = imageFile
            axios.put("lender/admin/list/company-details/", body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${cookies.get("access")}`,
                },
                withCredentials: true,
            }).then(res => {
                setShowAlert(res?.data?.Success)
                setOpen2(true)
                setTimeout(()=>{
                    handleClose();

                },1000)
            }).catch(err => {
                if (err?.response?.data?.email) {
                    setErrMsg(err?.response?.data?.email)
                    setOpen(true)
                } else if (err?.response?.data?.name) {
                    setErrMsg(err?.response?.data?.name)
                    setOpen(true)

                } else if (err?.response?.data?.logo) {
                    setErrMsg(err?.response?.data?.logo)
                    setOpen(true)

                } else {
                    setErrMsg("Server Error")
                    setOpen(true)

                }
            })
        }

    }
    function formatNumber(sanitizedValue) {
        let formatted = '';
        for (let i = 0; i < sanitizedValue?.length; i++) {
            if (i === 2 || i === 5 || i === 8) {
                formatted += `-${sanitizedValue[i]}`;
            } else {
                formatted += sanitizedValue[i];
            }
        }
        return formatted;
    }
    const HandleCompanyPhoneChange = (e) => {
        const value = e.target.value;

        const sanitizedValue = value.replace(/[^A-Za-z0-9+]/g, '');
        if (sanitizedValue?.length < 13) {
            const formatted = formatNumber(sanitizedValue)
            setCompanyData((prevFormData) => ({
                ...prevFormData,
                contact_number: formatted, // .replace(/-/g, '')
            }))
        }
    }
    const handleFileClick = (event) => {
        event.target.value = null;
    };
    const handleImageChange = (e) => {
        if (e.target.value) {
            const selectedImage = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageURL(event.target.result);
            };
            reader.readAsDataURL(selectedImage);
            setImageFile(selectedImage)
        }
    };
    return (
        <>
            {
                errMsg && (
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Alert
                            onClose={handleClose2}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {errMsg}
                        </Alert>
                    </Snackbar>
                )
            }
            {
                showAlert && (
                    <Snackbar
                        open={open2}
                        autoHideDuration={6000}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Alert
                            onClose={handleClose2}
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {showAlert}
                        </Alert>
                    </Snackbar>
                )
            }
            <div className="container mt-2 p-lg-5 p-md-5 p-4" >
                <Form noValidate validated={validated} className='' onSubmit={handleSignup}>
                    {/* <div className={errMsg ? "alert alert-danger alert-dismissible fade show" : "offscreen"}>
                        {errMsg}
                        <button type="button" className={errMsg ? "btn-close" : "d-none"} onClick={() => setErrMsg('')}></button>
                    </div>
                    <div className={showAlert ? "alert alert-info alert-dismissible fade show" : "offscreen"}>
                        {showAlert}
                        <button type="button" className={showAlert ? "btn-close" : "d-none"} onClick={() => setShowAlert('')}></button>
                    </div> */}
                    <h4 className='mb-4'>Company Information</h4>
                    <div className="profile-img mb-4">
                        <label htmlFor="logoPictureInputEdit"
                            className={`image-container ${isHovered ? 'hovered' : ''}`}>
                            <img
                                src={imageURL}
                                alt="Profile"
                                className="P-I"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            />
                            <input
                                type="file"
                                id="logoPictureInputEdit"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                                onClick={handleFileClick}
                            />
                            <span className="tooltip-text">Upload Image</span>
                        </label>
                    </div>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="name"
                            name={companyData.name}
                            value={companyData.name}
                            onChange={(e) => setCompanyData((prevFormData) => ({
                                ...prevFormData,
                                name: e.target.value,
                            }))}
                            pattern="^.{3,}$"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput2">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="email"
                            value={companyData.email}
                            name={companyData.email}
                            onChange={(e) => setCompanyData((prevFormData) => ({
                                ...prevFormData,
                                email: e.target.value,
                            }))}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            placeholder="Enter address"
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            value={companyData.address}
                            name={companyData.address}
                            onChange={(e) => setCompanyData((prevFormData) => ({
                                ...prevFormData,
                                address: e.target.value,
                            }))}
                            aria-describedby="uidnote"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid address name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput4">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Phone Number with (+1)"
                            name={companyData.contact_number}
                            value={companyData.contact_number}
                            onChange={HandleCompanyPhoneChange}
                            pattern="\+1-\d{3}-\d{3}-\d{4}"
                        />
                        <Form.Control.Feedback type="invalid">
                            Valid US phone number e.g.(+1-123-456-7890).
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3 mt-2' controlId='exampleForm.ControlInput5'>
                        <Form.Label>Theme Color</Form.Label>
                        <ColorPicker color={color} setColor={setColor} required={true} edit2={true} />
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput4">
                        <Form.Label className='Email' style={{ float: "left" }}>Equipment Category:</Form.Label>
                        <Form.Control as="select" type="select" name="payment_method"
                            onChange={(e) => setCompanyData((prevFormData) => ({
                                ...prevFormData,
                                equipment_category: e.target.value,
                            }))}
                            value={companyData.equipment_category}>
                            <option value="">Select an option</option>
                            {options.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please choose a valid option.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput6">
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Activate"
                            className='my-2'
                            //  value={formData.is_show_offers}
                            checked={companyData.is_active}
                            onChange={(e) => setCompanyData((prevFormData) => ({
                                ...prevFormData,
                                is_active: e.target.checked,
                            }))}
                        />
                    </Form.Group>
                    <div className="text-center">
                        <button className="btn btn-dark  px-5" type="submit">
                            {showLoader ? <Spinner style={{ width: "1.4rem", height: "1.4rem" }} /> : "Save"}</button>
                    </div>
                </Form>
            </div >
        </>
    )
}

export default EditCompanyInfo;