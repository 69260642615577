import React from 'react'
import Sidebar from '../components/mainpage/subcomponent/Sidebar'
import '../components/styles/dashboard.css'
import InReview from '../components/mainpage/subcomponent/inReview'
const MainInreview = () => {
    return (
        <div>
            <Sidebar />
            <div className='container-fluid'>
                <InReview />
            </div>
        </div>
    )
}

export default MainInreview