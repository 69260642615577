import React, { useState } from 'react';
import { lighten, transparentize } from 'polished';
import './ColorPicker.css';

function ColorPicker({ color, setColor, edit2, required }) {
    const selectedColor = (color && color !== "") ? color : "#000000";
    const hexCodeReg = /^#([0-9a-fA-F]{6})$/i;
    let hoverColor = "#000000"
    const [hover, setHover] = useState(false);
    const rgbaToHex = (rgbaColor) => {
        const [r, g, b, a] = rgbaColor.match(/\d+(\.\d+)?/g);
        const hexR = Math.round(Number(r)).toString(16).padStart(2, '0');
        const hexG = Math.round(Number(g)).toString(16).padStart(2, '0');
        const hexB = Math.round(Number(b)).toString(16).padStart(2, '0');
        return `#${hexR}${hexG}${hexB}`;
    };
    if (hexCodeReg.test(color)){
        hoverColor = rgbaToHex(transparentize(0.8, lighten(0.2, selectedColor)));
    }
    const handleColorChange = (event) => {
        let value = event.target.value;
        if (event.target.type === 'text') {
            if (!value.startsWith('#')) {
                value = '#' + value;
            }
            if (value.length > 7) {
                value = value.substring(0, 7);
            }
        }
        setColor(value);
    };
    return (
        <div className="color-picker">
            <input type="text" value={selectedColor} onChange={handleColorChange} className="color-text" required={required}
                disabled={!edit2} />
            <input
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                type="color" value={hover ? hoverColor : selectedColor} style={{ cursor: "pointer" }} onChange={handleColorChange} className="color-input" required={required}
                disabled={!edit2} />
        </div>
    );
}

export default ColorPicker;