import React, { useContext, useEffect, useRef, useState } from 'react'
import '../styles/login.css'
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { FaCircleUser } from "react-icons/fa6";
import Form from 'react-bootstrap/Form';
import { Spinner } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import SendEmailModal from '../../utils/modals/SendEmailModal';
import axios from '../../api/axios';
import useAuth from '.././logincomponent/subcomponent/hooks/useAuth'
import { GlobalContext } from '../../context/GlobalContext';
import img1 from '../../Images/logo.webp'
const cookies = new Cookies();
const LOGIN_URL = '/auth/login/';

const Login = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  // const from = location?.state?.from?.pathname || "/";
  const emailRef = useRef();
  const errRef = useRef();
  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setShowAlert] = useState('')
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(true);


  useEffect(() => {
    emailRef.current.focus();
  }, [])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    setTimeout(() => {
      if (state && state.signupResponse) {

        dispatch({
          type: 'SIGNUP_RES',
          payload: ''
        })


      }
    }, 60000)
  }, [])



  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setShowLoader(true);
      let token
      try {
        const response = await axios.post(
          LOGIN_URL, { email, password },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            withCredentials: true,
          }
        )
        const accessToken = response?.data?.access;
        localStorage.setItem("access", JSON.stringify(response?.data?.access));
        window.localStorage.setItem("isLoggedIn", "true")
        // localStorage.setItem("name", "abdul");
        cookies.set('access', response?.data?.access);
        cookies.set('refresh', response?.data?.refresh);
        // const roles = response?.data?.roles;
        setAuth({ email, password, accessToken });
        setEmail('');
        setPassword('');
        dispatch({
          type: "IS_LOGGED_IN",
          payload: true
        })
        setShowLoader(false);
        navigate("/", { replace: true });
      } catch (err) {
        setShowLoader(false);
        if (!err?.response) {
          setErrMsg('No Server Response');

        } else if (err.response?.status === 400) {
          setErrMsg('Missing email or Password');

        } else if (err.response?.status === 401) {
          if (err?.response?.data?.detail) {
            setErrMsg(err?.response?.data?.detail);
          } else {
            setErrMsg("email or password incorrect");
          }
        } else {
          setErrMsg('Login Failed');
        }
      }
    }
    setValidated(true)
  }
  return (
    <>
      {
        state && state?.signupResponse && (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: '100%' }}
            >
              {state.signupResponse}
            </Alert>
          </Snackbar>
        )
      }
      <div className='container main-square-box'>
        <SendEmailModal showModal={showModal} setShowModal={setShowModal} />
        <div className='row main-square-box-h-login  d-flex justify-content-center' >
          <div className='col-lg-10 color shadow p-3 rounded-4'>
            {errMsg && <div className="alert alert-danger alert-dismissible fade show mt-3" role="alert">
              <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setErrMsg("")}></button>
            </div>}


            <div className='centered-div mb-4'>
              <div className='form-div'>
                <div className='Icon-div'>
                  {/* <FaCircleUser className='Icon'></FaCircleUser> */}
                  <img src={img1} className='w-100' alt="logo" />

                </div>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className='Email'>Email:</Form.Label>
                    <Form.Control
                      type="email"
                      ref={emailRef}
                      autoComplete="off"
                      className="form-control mb-1"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      aria-describedby="uidnote"
                      required />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">

                    <Form.Label className='Passward'>Password:</Form.Label>
                    <Form.Control
                      type="password"
                      className="form-control mb-1"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      aria-describedby="pwdnote"
                      pattern={'^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$'}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid password.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <button
                    id='btn-radiuss' type="submit" className="btn btn-primary mt-2">
                    {showLoader ? <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /> : "Log In"}
                  </button>

                </Form>
                <Link className='mt-3 text-center' onClick={() => setShowModal(true)} to={""}>Forgot Password</Link>
                <p className='account-para mt-2'>Don't Have an Account? <Link to="/signup">Sign up</Link></p>
                <p className='privacy-links mt-2'>By clicking Continue, you agree to PAID's <Link to={"https://mazocapital.com/privacy-policy/"}>Privacy Policy</Link>,  <Link to="https://mazocapital.com/user-license-agreement/">User Agreement</Link>, and <Link to="https://mazocapital.com/terms-and-conditions/">Terms and Conditions</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login