import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import GenerateApiLink from '../../components/mainpage/subcomponent/applicationlink/subcomponent/GenerateApiLink';
const ApplicationLinkModal = ({
    showModal, setShowModal
}) => {
    const handleClose = () => {
        setShowModal(false)
    };
    return (
        <>
            <Modal show={showModal} onHide={handleClose}
                aria-labelledby="example-custom-modal-styling-title"
                dialogClassName="modal-width"
                centered
                animation={true}>
                <Modal.Body>
                    <div style={{ float: "right", fontSize: '20px' }}><CloseButton onClick={handleClose} variant="white" /></div>
                    <GenerateApiLink handleClose={handleClose} />
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ApplicationLinkModal;