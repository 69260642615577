import React, { useContext, useState } from 'react'
import Sidebar from '../components/mainpage/subcomponent/Sidebar'
import '../components/styles/dashboard.css'
import Missing from '../Missing'
import { GlobalContext } from '../context/GlobalContext'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import axios from '../api/axios'
import Cookies from 'universal-cookie';
import PaynetSummary from '../components/mainpage/subcomponent/PaynetSummary'
const cookies = new Cookies();
const MainPaynetReport = () => {
    const { state, dispatch } = useContext(GlobalContext);
    const [pageNotFound, setPageNotFound] = useState(false)
    const [dealSummary, setDealSummary] = useState();
    const [errMsg, setErrMsg] = useState("")
    const { slug } = useParams();
    useEffect(() => {
        axios.get(`lender/list/paynet-report/?application=${slug}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${cookies.get("access")}`,
            },
            withCredentials: true,
        }).then(res => {
            setDealSummary(res.data)
            dispatch({
                type: "INFORMATION_SLUG",
                payload: slug,
            });
        }).catch(err => {
            if (err?.response?.status === 404) {
                setErrMsg(true)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            {pageNotFound ? <Missing /> : state?.informationSlug !== "" &&
                <>
                    <Sidebar />
                    <div className='container-fluid mt-3'>
                        <div className={errMsg ? "alert alert-danger alert-dismissible fade show" : "offscreen"}>
                            {errMsg}
                            <button type="button" className={errMsg ? "btn-close" : "d-none"} onClick={() => setErrMsg('')}></button>
                        </div>
                        <PaynetSummary
                            reportSummary={dealSummary}
                        />
                    </div>
                </>}
        </div>
    )
}

export default MainPaynetReport