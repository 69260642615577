import React from 'react'
import Sidebar from '../components/mainpage/subcomponent/Sidebar'
import '../components/styles/dashboard.css'
import Offerpage from '../components/mainpage/subcomponent/offerpage'

const MainOfferpage = () => {
    return (
        <div>
            <Sidebar />
            <div className='container-fluid'>
                <Offerpage />
            </div>
        </div>
    )
}

export default MainOfferpage