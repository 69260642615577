import React from 'react'
import Sidebar from '../components/mainpage/subcomponent/Sidebar'
import '../components/styles/dashboard.css'
import ContractsOut from '../components/mainpage/subcomponent/contractsOut'
const MainContractOut = () => {
    return (
        <div>
            <Sidebar />
            <div className='container-fluid'>
                <ContractsOut />
            </div>
        </div>
    )
}

export default MainContractOut