import { Link, useParams } from 'react-router-dom';
import { useState } from 'react'
import Form from 'react-bootstrap/Form';
import axios from '../../../api/axios';
import Spinner from 'react-bootstrap/Spinner';

const ResetPassword = () => {
    const { token } = useParams();
    const [showAlert, setShowAlert] = useState(false)
    const [errMsg, setErrMsg] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [password, setPassword] = useState('');
    const [matchPassword, setMatchPassword] = useState('');
    const [validated, setValidated] = useState(false);

    const handleSignup = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setValidated(true);
            setErrMsg('')
            setShowAlert(false)
            setShowLoader(true)
            let body = {
                password: password,
            }
            axios.post(`auth/reset-password/${token}/`, body)
                .then(res => {
                    setShowAlert(true)
                    setShowLoader(false)
                }).catch(err => {
                    setShowLoader(false)
                    if (!err?.response) {
                        setErrMsg('No Server Response');
                    } else if (err.response?.status === 400) {
                        setErrMsg("You have already used this link to reset your password. Request again to reset.");
                    } else {
                        setErrMsg(err?.response?.data?.Error)

                    }
                })
        }
    }
    return (
        <>
            <div className="container mt-5 p-lg-5 p-md-5 p-4" >
                <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-8 col-12 mx-auto shadow p-lg-5 p-md-5 py-5 px-3 sign-form rounded" style={{ backgroundColor: "#fff" }}>
                        <Form className='text-center' noValidate validated={validated}
                            onSubmit={handleSignup}>
                            <div className={errMsg ? "alert alert-danger alert-dismissible fade show" : "offscreen"}>
                                {errMsg}
                                <button type="button" className={errMsg ? "btn-close" : "d-none"} onClick={() => setErrMsg('')}></button>
                            </div>
                            <div className={showAlert ? "alert alert-success alert-dismissible fade show" : "offscreen"}>
                                {showAlert && <>Password reset successfully. Please <Link to={"/login"} >
                                    Login
                                </Link> with new password.</>}
                                <button type="button" className={showAlert ? "btn-close" : "d-none"} onClick={() => setShowAlert('')}></button>
                            </div>
                            <h4 className='mb-4'>Reset Password</h4>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                <Form.Label className='Passward' style={{ float: 'left' }}>Password:</Form.Label>
                                <Form.Control
                                    type="password"
                                    className="form-control"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    aria-describedby="pwdnote"
                                    pattern={'^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$'}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid password.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                <Form.Label className='Passward' style={{ float: 'left' }}>Confirm Password:</Form.Label>
                                <Form.Control
                                    type="password"
                                    className='form-control'
                                    value={matchPassword}
                                    onChange={(e) => setMatchPassword(e.target.value)}
                                    required
                                    aria-describedby="confirmnote"
                                    pattern={`^${password}$`}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Must match with the password field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className='text-center'>
                                <div>
                                    <button
                                        type="submit"
                                        className="btn btn-dark mt-2 px-5">
                                        {showLoader ? <Spinner style={{ width: "1.4rem", height: "1.4rem" }} /> : "Change Password"}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ResetPassword;