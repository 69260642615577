import React from 'react'
import { useState } from 'react';
import AddUser from './AddUser';
import AllUsers from './AllUsers';
import Sidebar from '../../components/mainpage/subcomponent/Sidebar';
import Users from './Users';
import Missing from '../../Missing';

const AdminPanel = () => {
    const [users, setUsers] = useState([]);
    const role = 'admin';
    // const role = 'user';
    const addUser = (user) => {
        // Add the new user to the users array
        setUsers([...users, user]);
    };

    return (
        <>
            {role === 'admin' ? <><Sidebar />
                <div className="App">
                    {/* <AddUser addUser={addUser} />
                <AllUsers users={users} /> */}
                    <Users />
                </div></>
                :
                <Missing />}
        </>
    );
}

export default AdminPanel