import React from 'react'
import '../../styles/dashboard.css';
import { Table } from 'react-bootstrap';

const Vendortable = ({data4}) => {
  return (
    <>
    <Table className='table-backgorund'>
                <thead className='table-head'>
                    <tr>
                        <th className='deal-width'>STATUS</th>
                        <th className='deal-width'>DEAL</th>
                    </tr>
                </thead>
                <tbody>
                        <div style={{ marginBottom: "0.5rem" }}></div>
                        <tr className='deal'>
                            <td>{data4?.status}</td>
                            <td>{data4?.company_name}</td>

                        </tr>


                </tbody>
            </Table>
    </>
  )
}

export default Vendortable