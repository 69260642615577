import React, { useContext, useEffect } from 'react'
import '../../styles/dashboard.css';
import { BiEditAlt } from 'react-icons/bi';
import Vendortable from './vendortable';
import TermsConditionTable from './termsConditionTable';
import { BiUpload } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import UploadFilesModal from '../../../utils/modals/UploadFilesModal';
import axios from '../../../api/axios';
import Cookies from 'universal-cookie';
import LoadingBar from 'react-top-loading-bar'
import { GlobalContext } from '../../../context/GlobalContext';
import { getBaseStyle, getHoverStyle } from '../../../utils/styles';
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const cookies = new Cookies();
const Dealpage = () => {
    const [progress, setProgress] = useState(30);
    const [showPage, setShowPage] = useState(false);
    const navigate = useNavigate();
    const { slug, status } = useParams();
    const [showModal, setShowModal] = useState(false)
    // const [list, setList] = useState({})
    const [dealDetails, setDealDetails] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [buttonHover, setButtonHover] = useState(false)
    const [buttonHover2, setButtonHover2] = useState(false)
    const { state } = useContext(GlobalContext)
    const baseColor = state?.user?.themeColor;
    const baseStyle = getBaseStyle(baseColor);
    const hoverStyle = getHoverStyle(baseColor);
    const [reportNotFound, setReportNotFound] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [open, setOpen] = useState(false);
    const [notes, setNotes] = useState({});
    const [stips, setStips] = useState([]);
    const [unreadNotes, setUndreadNotes] = useState(0)
    const [unreadStips, setUndreadStips] = useState(0)
    const [modelOpen, setModelOpen] = useState(false)
    const style = {
        maxHeight: '350px',
        overflowY: 'scroll',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        borderRadius: '10px',
        padding: '22px',
        border: '1px solid grey',
        boxShadow: 24,
        p: 4,

        ul: {
            listStyleType: 'none',
            padding: 0,
        },

        li: {
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
            marginBottom: '10px',
            borderBottom: '1px solid #ddd',
            paddingBottom: '10px',
        },

        span: {
            marginRight: '10px',
            color: '#333',
            display: 'flex',
            alignItems: 'center'

        },

        p: {
            margin: 0,
            color: '#666',
        },

    };


    const handleClose = (event, reason) => {

        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        axios.get(`lender/list/deal-details/?application=${slug}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${cookies.get("access")}`,
            },
            withCredentials: true,
        }).then(res => {
            setDealDetails(res.data)
        }).catch(err => {
            if (err?.response?.status === 401) {
                window.localStorage.setItem("isLoggedIn", "false");
                navigate("/login");
            }
        })
        axios.get(`lender/list/deal-activity/?application=${slug}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${cookies.get("access")}`,
            },
            withCredentials: true,
        }).then(res => {
            const allStips = res.data.credit_stips.concat(res.data.funding_stips);
            const allUnreadStips = res.data.total_unread_credit_stips + res.data.total_unread_funding_stips;
            setStips(allStips);
            setNotes(res.data.notes || {});
            setUndreadNotes(res.data.total_unread_notes);
            setUndreadStips(allUnreadStips);
        }).catch(err => {
            if (err?.response?.status === 401) {
                window.localStorage.setItem("isLoggedIn", "false");
                navigate("/login");
            }
        })

    }, [refresh])

    useEffect(() => {
        axios.get(`lender/list/paynet-report/?application=${slug}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${cookies.get("access")}`,
            },
            withCredentials: true,
        }).then(res => {
        }).catch(err => {
            if (err?.response?.status === 404) {
                setReportNotFound(true)
            }
        })
    }, [])

    useEffect(() => {
        if (dealDetails?.application_data) {
            setProgress(100)
            setShowPage(true)
        }
    }, [dealDetails])
    const HandlePullPaynetReport = () => {
        axios.post(`lender/pull-paynet-report/`, { uuid: slug }, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${cookies.get('access')}`
            },
            withCredentials: true
        }).then(res => {
            setReportNotFound(false);
        }).catch(err => {
            if (err?.response?.status === 400) {
                setErrMsg('Something went wrong. Please try again later')
            }
            else
                setErrMsg(err?.response?.data?.Error)
            // setErrMsg('hello world')
            setOpen(true)
        })
    }
    const updateStatusFunc = async () => {
        setModelOpen(true)
        if (unreadNotes > 0) {
            const body = {
                object: 'notes'
            }
            axios.put(`lender/update/deal-activty/?application=${slug}`, body, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${cookies.get("access")}`,
                },
                withCredentials: true,
            })
                .then((res) => {
                    setUndreadNotes(0)
                })
                .catch(err => {})
        }
    }
    const closeModal = () => {
        setModelOpen(false);
        notes.is_read = true;

    };
    function formatDescription(description) {
        // Replace special characters with their HTML entity equivalents to prevent XSS attacks
        const escapedDescription = description
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;");

        // Preserve line breaks
        const formattedDescription = escapedDescription.replace(/\r?\n/g, "<br>");

        return formattedDescription;
    }
    return (
        <>
            <LoadingBar
                color="rgba(0, 148, 255, 1)"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <UploadFilesModal
                showModal={showModal}
                setShowModal={setShowModal}
                uploadedFiles={dealDetails?.uploaded_files}
                refresh={refresh}
                setRefresh={setRefresh}
                dealId={dealDetails?.deal}
                setProgress={setProgress}
            />
            {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
            {
                errMsg && (
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Alert
                            onClose={handleClose}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {errMsg}
                        </Alert>
                    </Snackbar>
                )
            }

            {showPage && (<div className='row mt-4 mb-4'>
                <div className='col-lg-12 col-md-12'>
                    <div className='colorr shadow p-3 rounded-4'>
                        <div className='d-flex'>
                            <div>
                                <h4>Application</h4>
                            </div>
                            <div className='app-btn ms-auto'>
                                <button
                                    type="button"
                                    onClick={() => navigate(`/application/${slug}/details`)}
                                    className="btn btn-primary edit-btn "
                                    onMouseEnter={() => setButtonHover(true)}
                                    onMouseLeave={() => setButtonHover(false)}
                                    style={state?.user?.role !== "Staff" ? { backgroundColor: 'rgba(0, 148, 255, 1)', color: "rgba(255, 255, 255, 1)" } : buttonHover ? hoverStyle : baseStyle}
                                ><BiEditAlt /> View Application Details</button>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-lg-12 col-md-12'>
                                <div className="App mt-3">
                                    <Vendortable data4={dealDetails?.application_data} />
                                </div>

                            </div>
                        </div>
                        <div className='row mt-3'>
                            <h5>TERMS & CONDITIONS</h5>
                            <div className='col-lg-12 col-md-12'>
                                <div className="App mt-3">
                                    <TermsConditionTable termsAndConditions={dealDetails?.terms_and_conditions} />
                                </div>
                                <div>
                                    {
                                        state.user.isShowReports && (
                                            <>
                                                <button
                                                    onMouseEnter={() => setButtonHover2(true)}
                                                    onMouseLeave={() => setButtonHover2(false)}
                                                    style={state?.user?.role !== "Staff" ? { backgroundColor: 'rgba(0, 148, 255, 1)', color: "rgba(255, 255, 255, 1)" } : buttonHover2 ? hoverStyle : baseStyle}
                                                    type="button" onClick={() => {
                                                        if (reportNotFound) {
                                                            HandlePullPaynetReport();
                                                        } else {
                                                            navigate(`/${status}/${slug}/paynet-report`)
                                                        }
                                                    }} className="btn btn-primary equipment-btn credit-btn ms-2">{reportNotFound ? "Pull Paynet Report" : "View Paynet Report"}
                                                </button>
                                                <button
                                                    onMouseEnter={() => setButtonHover2(true)}
                                                    onMouseLeave={() => setButtonHover2(false)}
                                                    style={state?.user?.role !== "Staff" ? { backgroundColor: 'rgba(0, 148, 255, 1)', color: "rgba(255, 255, 255, 1)" } : buttonHover2 ? hoverStyle : baseStyle}
                                                    type="submit" onClick={() => navigate(`/${status}/${slug}/summary`)} className="btn btn-primary equipment-btn credit-btn ms-2">Credit Summary
                                                </button>
                                            </>
                                        )
                                    }

                                    {
                                        dealDetails.credit_summary_pdf != null && state.user.isShowReports && (
                                            <button
                                                onMouseEnter={() => setButtonHover2(true)}
                                                onMouseLeave={() => setButtonHover2(false)}
                                                style={state?.user?.role !== "Staff" ? { backgroundColor: 'rgba(0, 148, 255, 1)', color: "rgba(255, 255, 255, 1)" } : buttonHover2 ? hoverStyle : baseStyle}
                                                type="submit" onClick={() => window.open(dealDetails.credit_summary_pdf, "_blank")} className="btn btn-primary equipment-btn credit-btn ms-2">View Credit Summary Pdf
                                            </button>
                                        )
                                    }
                                    {
                                        dealDetails.paynet_report_pdf != null && state.user.isShowReports && (
                                            <button
                                                onMouseEnter={() => setButtonHover2(true)}
                                                onMouseLeave={() => setButtonHover2(false)}
                                                style={state?.user?.role !== "Staff" ? { backgroundColor: 'rgba(0, 148, 255, 1)', color: "rgba(255, 255, 255, 1)" } : buttonHover2 ? hoverStyle : baseStyle}
                                                type="submit" onClick={() => window.open(dealDetails.paynet_report_pdf, "_blank")} className="btn btn-primary equipment-btn credit-btn">View Paynet Report Pdf
                                            </button>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                        <div className='row mt-4 mb-4'>
                            <div className='col-lg-12 col-md-12 '>
                                <div className='deal-activities'>
                                    <h5 className='text-center p-2 deal-activity'>DEAL ACTIVITY</h5>
                                    <div className='deal-activities'></div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6'>

                                            <div className='p-2'>
                                                <div className='p-2'>
                                                    <button type="submit" className="notes-btn p-2" onClick={updateStatusFunc}>NOTES {unreadNotes > 0 && <span>{unreadNotes}</span>}</button>
                                                    {/* <button type="submit" className="notes-btn p-2" onClick={updateStatusFunc}>NOTES  <span>2</span></button> */}

                                                    <Modal
                                                        open={modelOpen}
                                                        onClose={closeModal}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"

                                                    >
                                                        <Box sx={style}>
                                                            <h2 style={{ marginBottom: '20px', textAlign: 'center', color: '#333' }}>Notes</h2>
                                                            <ul>
                                                                {
                                                                    notes && notes.description ? (
                                                                        <>
                                                                            <li style={{ marginBottom: '4px', width: '100%' }}>
                                                                                <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} dangerouslySetInnerHTML={{
                                                                                    __html: `${formatDescription(notes.description)} ${notes.is_read === false ? '<span class="new">new</span>' : ''}`
                                                                                }} />
                                                                            </li>
                                                                        </>
                                                                    ) : (
                                                                        <p>You don't have any note yet.</p>
                                                                    )
                                                                }
                                                            </ul>
                                                        </Box>
                                                    </Modal>
                                                </div>
                                                {/* <div className='p-2'>
                                                    <button type="submit" className="notes-btn p-2">NEXT STEPS</button>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6'>
                                            <div className='p-2'>
                                                {/* <div className='p-2'> */}
                                                {/* <button type="submit" className="notes-btn p-2">STIPS</button> */}
                                                {/* <Accordion>
                                                        <AccordionSummary
                                                            // expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1-content"
                                                            id="panel1-header"
                                                        >
                                                            <button type="submit" className="notes-btn p-2">STIPS {unreadStips && <span>{unreadStips}</span>} </button>
                                                            <Typography>Accordion 1</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                            </Typography>

                                                        </AccordionDetails>
                                                    </Accordion> */}
                                                {/* </div> */}
                                                <div className='p-2'>
                                                    <button
                                                        type="submit"
                                                        className="upload-btn p-2 d-flex justify-content-center"
                                                        onClick={() => setShowModal(true)}
                                                    >
                                                        <BiUpload className='upload-icon mb-1' /> <span>Upload</span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
        </>
    )
}

export default Dealpage