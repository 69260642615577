import React from 'react'
import '../../styles/dashboard.css';
import Divider from '@mui/material/Divider';
import { IoArrowBack } from "react-icons/io5";

const Creditsummary = ({
    dealSummary,
    personalInformation,
    report,
}) => {
    return (
        <>
            <div className='back-btn' onClick={()=>window.history.back()}>
                <IoArrowBack size={30}/>

            </div>
            <div className='row mt-4 mb-4'>
                <div className='col-lg-12 col-md-12'>
                    <div className='colorr shadow p-3 rounded-4'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <div>
                                    <h5>Personal Information</h5>
                                </div>
                                <div className='personal-info-div mt-3 p-3'>
                                    <div className='personal-info'>
                                        <p className='mb-1'>Name: <span className='personal-values1'>{personalInformation?.name}</span></p>
                                        <Divider className='p-divider' />
                                        <p className='mb-1'>Date of Birth: <span className='personal-values2'>{personalInformation?.date_of_birth}</span></p>
                                        <Divider className='p-divider' />
                                        <p className='mb-1'>SSN: <span className='personal-values3'>{personalInformation?.ssn_number}</span></p>
                                        <Divider className='p-divider' />
                                        <p className='mb-1'>Current Address: <span className='personal-values4'>{personalInformation?.address}</span></p>
                                        <Divider className='p-divider' />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6'>
                                <div className='h-dealing'>
                                    <h5>Deal Summary.</h5>
                                </div>
                                <div className='personal-info-div mt-3 p-3'>
                                    <p className='mb-1'>Contingent Terms:<span className='deal-sumary'>{dealSummary?.contingent_terms}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Equipment:<span className='deal-sumary'>{dealSummary?.target_purchase}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Equipment Cost:<span className='deal-sumary'>{dealSummary?.equipment_cost}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Time in Business:<span className='deal-sumary'>{dealSummary?.business_duration}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Annual Revenue:<span className='deal-sumary'>{dealSummary?.annual_business_revenue}</span></p>
                                    <Divider className='p-divider' />
                                    {/* <p className='mb-1'>Industry:<span className='deal-sumary'>{dealSummary?.entity_type}</span></p>
                                    <Divider className='p-divider' /> */}
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5 className='mt-3'>Report Overview</h5>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <div className='report-overview-div mt-3 p-3'>
                                    <p className='mb-1'>Time in Bureau:<span className='deal-sumary'>{report?.time_in_bureau}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Total Trades:<span className='deal-sumary'>{report?.total_trades}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Total Healthy Trades:<span className='deal-sumary'>{report?.total_healthy_trades ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Open Relevant Trades:<span className='deal-sumary'>{report?.open_relevant_trades ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Open Healthy Trades:<span className='deal-sumary'>{report?.open_healthy_trades ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Open Negative Trades:<span className='deal-sumary'>{report?.open_negative_trades ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Open Past Due Trades:<span className='deal-sumary'>{report?.open_past_due_trades ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Charge Offs (non-medical)<span className='deal-sumary'>{report?.charges_off_non_medical ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Charge Offs (unpaid):<span className='deal-sumary'>{report?.charges_off_unpaid ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Collections (non-medical):<span className='deal-sumary'>{report?.collections_non_medical ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>30 Day Lates:<span className='deal-sumary'>{report?.thirty_days_late ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>60 Day Lates:<span className='deal-sumary'>{report?.sixty_days_late ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>90 Day Lates:<span className='deal-sumary'>{report?.ninety_days_late ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Inquiries (last 24 months):<span className='deal-sumary'>{report?.inquiries_last_24_months ?? 'TBD'}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Inquiries (last 6 months):<span className='deal-sumary'>{report?.inquiries_last_6_months ?? 'TBD'}</span></p>
                                    <Divider className='p-divider' />
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6'>
                                <div className='report-overview-div mt-3 p-3'>
                                    <p className='mb-1'>FICO Score:<span className='deal-sumary'>{report?.fico_score ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Current Revolving Balance:<span className='deal-sumary'>{report?.current_revolving_balance ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Current Revolving Limit:<span className='deal-sumary'>{report?.current_revolving_limit ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Current Revolving Available ($/%):<span className='deal-sumary'>{report?.current_revolving_available ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Current Mortgage Balance (if any):<span className='deal-sumary'>{report?.current_mortgage_balance ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>High Comp (24 months):<span className='deal-sumary'>{report?.high_comp_24_months ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>High Comp (12 months):<span className='deal-sumary'>{report?.high_comp_12_months ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Total Installment Debt:<span className='deal-sumary'>{report?.total_installment_debt ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Child Support (Current):<span className='deal-sumary'>{report?.child_support_current ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Child Support (Past Due):<span className='deal-sumary'>{report?.child_support_past_due ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Bankruptcies:<span className='deal-sumary'>{report?.bankruptcies ?? 0}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Last Bankruptcy Status:<span className='deal-sumary'>{report?.last_bankruptcy_status ?? 'N/A'}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Bankruptcy Type:<span className='deal-sumary'>{report?.bankruptcy_type ?? 'N/A'}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Bankruptcy Date of Disposition:<span className='deal-sumary'>{report?.bankruptcy_date_of_disposition ?? 'N/A'}</span></p>
                                    <Divider className='p-divider' />
                                </div>
                            </div>
                            <div>
                                <div className='mt-3 mb-5'>
                                    <h5>Notes:</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default Creditsummary