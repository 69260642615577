import tinycolor from 'tinycolor2';

export const getBaseStyle = (baseColor) => {
    return {
        backgroundColor: baseColor,
        color: tinycolor(baseColor).isLight() ? '#000' : '#fff',
        borderColor: tinycolor(baseColor).darken(10).toString(),
    };
};

export const getHoverStyle = (baseColor) => {
    const baseStyle = getBaseStyle(baseColor);

    return {
        ...baseStyle,
        backgroundColor: tinycolor(baseColor).lighten(10).toString(),
    };
};
