import React from 'react'
import { useState } from 'react'
import { Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import axios, { axiosPrivate } from '../../../../api/axios';
import Cookies from 'universal-cookie';
import { useEffect } from 'react';
import '../../../styles/dashboard.css'
import { Checkbox, FormControlLabel } from '@mui/material';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
const cookies = new Cookies();

const EditProfile = ({ handleClose }) => {
    const [showAlert, setShowAlert] = useState('')
    const [errMsg, setErrMsg] = useState('');
    const [showLoader] = useState(false);
    const [validated, setValidated] = useState(false);
    const [imageFile, setImageFile] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_no: '',
        profile_picture: '',
        company_name: '',
        address: '',
        is_show_offers: false,
    });
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleClose2 = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
        setOpen2(false)
    };

    useEffect(() => {
        axiosPrivate.get("auth/user/profile/", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${cookies.get("access")}`,
            },
            withCredentials: true,
        }).then((response) => {
            const responseData = response.data;
            setFormData({
                username: responseData?.username ?? "",
                first_name: responseData?.first_name ?? "",
                last_name: responseData?.last_name ?? "",
                email: responseData?.email ?? "",
                phone_no: formatNumber(responseData?.phone_no) ?? "",
                profile_picture: responseData?.profile_picture ?? "",
                is_show_offers: responseData?.is_show_offers ?? false,
                company_name: responseData?.company_name ?? "",
                address: responseData?.address ?? ""
            });
            setImageURL(responseData?.profile_picture);
        })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [])
    const handleSignup = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        setErrMsg('');
        setShowAlert('');
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true)
        } else {
            setValidated(false)
            let body = { ...formData }
            body.phone_no = formData.phone_no.replace(/-/g, '')
            body.profile_picture = imageFile
            axios.put("auth/user/profile/", body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${cookies.get("access")}`,
                },
                withCredentials: true,
            }).then(res => {
                setShowAlert(res?.data?.Success)
                setOpen2(true)
                setTimeout(() => {
                    handleClose();

                }, 1000)
            }).catch(err => {
                if (err?.response?.data?.email) {
                    setErrMsg(err?.response?.data?.email)
                    setOpen(true)
                } else if (err?.response?.data?.name) {
                    setErrMsg(err?.response?.data?.name)
                    setOpen(true)

                } else {
                    setErrMsg("Server Error")
                    setOpen(true)

                }
            })
        }

    }
    function formatNumber(sanitizedValue) {
        let formatted = '';
        for (let i = 0; i < sanitizedValue?.length; i++) {
            if (i === 2 || i === 5 || i === 8) {
                formatted += `-${sanitizedValue[i]}`;
            } else {
                formatted += sanitizedValue[i];
            }
        }
        return formatted;
    }
    const HandlePhoneChange = (e) => {
        const value = e.target.value;

        const sanitizedValue = value.replace(/[^A-Za-z0-9+]/g, '');
        if (sanitizedValue?.length < 13) {
            const formatted = formatNumber(sanitizedValue)
            setFormData((prevFormData) => ({
                ...prevFormData,
                phone_no: formatted, // .replace(/-/g, '')
            }))
        }
    }
    const handleFileClick = (event) => {
        event.target.value = null;
    };
    const handleImageChange = (e) => {
        if (e.target.value) {
            const selectedImage = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageURL(event.target.result);
            };
            reader.readAsDataURL(selectedImage);
            setImageFile(selectedImage)
        }
    };
    return (
        <>
            {
                errMsg && (
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Alert
                            onClose={handleClose2}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {errMsg}
                        </Alert>
                    </Snackbar>
                )
            }
            {
                showAlert && (
                    <Snackbar
                        open={open2}
                        autoHideDuration={6000}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Alert
                            onClose={handleClose2}
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {showAlert}
                        </Alert>
                    </Snackbar>
                )
            }
            <div className="container mt-2 p-lg-5 p-md-5 p-4" >
                <Form noValidate validated={validated} className='' onSubmit={handleSignup}>
                    {/* <div className={errMsg ? "alert alert-danger alert-dismissible fade show" : "offscreen"}>
                        {errMsg}
                        <button type="button" className={errMsg ? "btn-close" : "d-none"} onClick={() => setErrMsg('')}></button>
                    </div>
                    <div className={showAlert ? "alert alert-info alert-dismissible fade show" : "offscreen"}>
                        {showAlert}
                        <button type="button" className={showAlert ? "btn-close" : "d-none"} onClick={() => setShowAlert('')}></button>
                    </div> */}
                    <h4 className='mb-4'>Personal Information</h4>
                    <div className="profile-img mb-4">
                        <label htmlFor="profilePictureInputEdit"
                            className={`image-container ${isHovered ? 'hovered' : ''}`}>
                            <img
                                src={imageURL}
                                alt="Profile"
                                className="P-I"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            />
                            <input
                                type="file"
                                id="profilePictureInputEdit"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                                onClick={handleFileClick}
                            />
                            <span className="tooltip-text">Upload Image</span>
                        </label>
                    </div>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1">
                        <Form.Label>User Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="username"
                            name={formData.username}
                            value={formData.username}
                            onChange={(e) => setFormData((prevFormData) => ({
                                ...prevFormData,
                                username: e.target.value,
                            }))}
                            pattern="^(?!.*\s).{3,}$"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid username.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput2">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name={formData.first_name}
                            placeholder="firstname"
                            value={formData.first_name}
                            onChange={(e) => setFormData((prevFormData) => ({
                                ...prevFormData,
                                first_name: e.target.value,
                            }))}
                            pattern={'^.{3,}$'}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid firstname.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="lastname"
                            name={formData.last_name}
                            value={formData.last_name}
                            onChange={(e) => setFormData((prevFormData) => ({
                                ...prevFormData,
                                last_name: e.target.value,
                            }))}
                            pattern={'^.{3,}$'}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid lastname.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput4">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="email"
                            value={formData.email}
                            name={formData.email}
                            onChange={(e) => setFormData((prevFormData) => ({
                                ...prevFormData,
                                email: e.target.value,
                            }))}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput7">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Phone Number with (+1)"
                            name={formData.phone_no}
                            value={formData.phone_no}
                            onChange={HandlePhoneChange}
                            pattern="\+1-\d{3}-\d{3}-\d{4}"
                        />
                        <Form.Control.Feedback type="invalid">
                            Valid US phone number e.g.(+1-123-456-7890).
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput8">
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Show offers"
                            //  value={formData.is_show_offers}
                            checked={formData.is_show_offers}
                            className='my-2'
                            onChange={(e) => setFormData((prevFormData) => ({
                                ...prevFormData,
                                is_show_offers: e.target.checked,
                            }))}
                        />
                    </Form.Group>
                    <div className="text-center">
                        <button className="btn btn-dark  px-5" type="submit">
                            {showLoader ? <Spinner style={{ width: "1.4rem", height: "1.4rem" }} /> : "Save"}</button>
                    </div>
                </Form>
            </div >
        </>
    )
}

export default EditProfile