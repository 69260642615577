import React, { useEffect, useState } from "react";
import "../../styles/dashboard.css";
import DataTable from "./DataTable";
import { CiSearch } from "react-icons/ci";
import axios from "../../../api/axios";
import Cookies from "universal-cookie";
import PaginationComponent from "../../../utils/Pagination";
import QueryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import image from "../../../Images/Frame-31.webp";
import LoadingBar from "react-top-loading-bar";
import { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { getBaseStyle, getHoverStyle } from "../../../utils/styles";
import Filterbtn from "./Filterbtn";
const cookies = new Cookies();

const ContractsOut = () => {
  const [progress, setProgress] = useState(30);
  const [showPage, setShowPage] = useState(false);
  const location = useLocation();
  const page_no = parseInt(QueryString.parse(location.search)?.page_no);
  const [contractOut, setContractOut] = useState();
  const [refreshData, setRefreshData] = useState(false);
  const [activePage, setActivePage] = useState(page_no ? page_no : 1); // Set the initial active page
  const [pageCount, setPageCount] = useState(0);
  const search = QueryString.parse(location.search)?.search;
  const [searchHover, setSearchHover] = useState(false);
  const { state } = useContext(GlobalContext);
  const baseColor = state?.user?.themeColor;
  const baseStyle = getBaseStyle(baseColor);
  const hoverStyle = getHoverStyle(baseColor);
  const [paramSearch, setParamSearch] = useState(true);
  const [searchString, setSearchString] = useState(search ? search : "");
  const navigate = useNavigate();
  const [filters, setFilters] = useState("My Apps");
  const recordsPerPage = 10;
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const updateQueryParams = (newParams) => {
    const currentParams = QueryString.parse(window.location.search);

    if (!newParams.hasOwnProperty("search")) {
      delete currentParams.search;
    }

    const updatedParams = { ...currentParams, ...newParams };

    const updatedQueryString = QueryString.stringify(updatedParams);

    // Update the URL with the new query string
    window.history.pushState({}, "", `?${updatedQueryString}`);

    // You can also use React Router navigation if you're using it
    // router.navigate(`?${updatedQueryString}`);
  };
  const GetApplicationData = () => {
    setProgress(30);
    axios
      .get(
        `lender/list-applications/status/?limit=${recordsPerPage}&offset=${
          (activePage - 1) * recordsPerPage
        }&search=${searchString}&status=${"Contracts Out"}&filter_type=${filters}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${cookies.get("access")}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        setProgress(100);
        setShowPage(true);
        setContractOut(res?.data?.applications);
        const count = res?.data?.count;
        const numberOfPages = Math.ceil(count / recordsPerPage);
        setPageCount(numberOfPages);
        updateQueryParams(
          searchString !== ""
            ? { page_no: activePage, search: searchString }
            : { page_no: activePage }
        );
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  };
  useEffect(() => {
    if (searchString === "") {
      GetApplicationData();
      setParamSearch(false);
    } else if (search !== "" && paramSearch) {
      GetApplicationData();
      setParamSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, searchString, filters, refreshData]);
  return (
    <>
      <LoadingBar
        color="rgba(0, 148, 255, 1)"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      {showPage && (
        <div className="row mt-4 mb-4">
          <div className="col-lg-12 col-md-12">
            <div className="colorr shadow p-3 rounded-4 notCompleted-height">
              <div className="app-serach-field">
                <div>
                  <h4>Applications Contracts Out</h4>
                </div>
                <div className="ms-auto">
                  <div className="icon-field">
                    <CiSearch className="ms-2 icon-search" />
                    <input
                      className="search-here input-filed search-bar-field"
                      type="text"
                      name={searchString}
                      placeholder="Search here..."
                      value={searchString}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          GetApplicationData();
                        }
                      }}
                      onChange={(e) => {
                        setSearchString(e.target.value);
                      }}
                    />
                    <input
                      type="button"
                      onClick={GetApplicationData}
                      className="search-bar-btn"
                      onMouseEnter={() => setSearchHover(true)}
                      onMouseLeave={() => setSearchHover(false)}
                      name=""
                      value="Search"
                      style={
                        state?.user?.role !== "Staff"
                          ? {}
                          : searchHover
                          ? hoverStyle
                          : baseStyle
                      }
                    />
                  </div>
                </div>
              </div>
              {state?.user?.role !== "Individual" && (
                <div className="app-filters-div mt-3 d-flex justify-content-end">
                  <Filterbtn filters={filters} setFilters={setFilters} />
                </div>
              )}
              <div className="row mt-3">
                <div className="col-lg-12 col-md-12">
                  <div className="App mt-3">
                    <DataTable
                      data={contractOut}
                      status={"contract-out"}
                      setRefreshData={setRefreshData}
                    />
                  </div>
                  <div>
                    {pageCount > 1 && (
                      <PaginationComponent
                        pageCount={pageCount}
                        activePage={activePage}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              </div>
              {contractOut?.length < 1 && (
                <div className="no-data">
                  <img src={image} alt="logo" className="data-display" />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContractsOut;
