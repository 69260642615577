import { useReducer } from "react";
import { GlobalContext } from "./GlobalContext";
import { reducer } from "./Reducer";

const initialState = {
  questions: {
    business: {
      companyName: "",
      longBussiness: null,
      entityType: "",
      bussinessAnual: null,
      companyAddress: "",
      homeAddress: "",
      ownershipPercentage: null,
      secondOwnerEmail: "",
      secondOwnerPhone: "+1",
      secondOwnerFirstName: "",
      secondOwnerLastName: "",
      secondOwnerDob: "",
      secondOwnerSSN: "",
      secondOwnerHomeAddress: "",
      taxId: "",
      homeowner: false,
      percentage: 0,
    },
    personal: {
      suffix: "",
      maritalStatus: "",
      residentialAddress: "",
      isPlanToRegisterVehicle: true,
      mailingAddress: "",
      livingYears: "",
      livingMonths: "",
      livingStatus: "",
      livingMonthlyPayment: null,
      employmentStatus: "",
      retirementIncome: null,
      retirementYears: "",
      retirementMonths: "",
      businessName: "",
      businessPhone: "+1",
      occupationTitle: "",
      employerAddress: "",
      grossMonthlyIncome: null,
      employmentYears: "",
      employmentMonths: "",
      additionalIncomeSource: "",
      additionalMonthlyIncome: null,
      isCoApplicant: false,
      coApplicantEmail: "",
      coApplicantPhone: "+1",
      coApplicantFirstName: "",
      coApplicantMiddleName: "",
      coApplicantLastName: "",
      coApplicantDob: "",
      coApplicantSsn: "",
      coApplicantCitizenship: "",
      coApplicantSuffix: "",
      coApplicantMaritalStatus: "",
      coApplicantResidentialAddress: "",
      coApplicantLivingYears: "",
      coApplicantLivingMonths: "",
      coApplicantLivingMonthlyPayment: null,
      coApplicantLivingStatus: "",
      coApplicantEmploymentStatus: "",
      coApplicantretirementIncome: null,
      coApplicantretirementYears: "",
      coApplicantretirementMonths: "",
      coApplicantAdditionalIncomeSource: "",
      coApplicantAdditionalMonthlyIncome: null,
      coApplicantBusinessName: "",
      coApplicantEmployerAddress: "",
      coApplicantGrossMonthlyIncome: null,
      coApplicantEmploymentYears: "",
      coApplicantEmploymentMonths: "",
      dealerName: "",
      dealerPhone: "+1",
      salespersonName: "",
      financingYear: "",
      financingMake: "",
      financingModel: "",
      salesprice: null,
      downPayment: null,
    },
    email: "",
    phone: "+1",
    firstname: "",
    middlename: "",
    lastname: "",
    dob: "",
    ssn: "",
    citizenship: "",
    information: "",
    imageFile: null,
    imageName: "",
    fontFamily: "null",
    requestedAmount: null,
    isMarketingLink: false,
    equipmentName: "",
    equipmentCost: null,
    equipmentUse: "Business",
    referrerUrl: "",
    referrerEquipmentDescription: "",
    referrerEquipmentCost: null,
    referrerEquipmentImageUrl: "",
    referrerEquipmentMonthlyCost: null,
  },
  prequalifiedOffers: [],
  refreshApplications: false,
  informationSlug: "",
  applicationUUID: "",
  refreshApplicationLink: false,
  isLoggedIn: false,
  refreshProfile: false,
  signupResponse: "",
  isPersonalFlow: false,

  user: {
    username: "",
    role: "",
    logo: "",
    themeColor: "",
    profilePicture: null,
    companyCategory: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    companyName: "",
    address: "",
    isShowOffers: false,
    isShowReports: false,
  },
};
export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
