import { createContext } from "react";

const values = {
  state: {
    questions: {
      business: {
        equipmentUse: "",
        companyName: "",
        longBussiness: "",
        entityType: "",
        purchase: "",
        bussinessAnual: "",
        monthlyIncome: "",
        industry: "",
        dob: "",
        // address: "",
        homeAddress: "",
        companyAddress: "",
        ssn: "",
        citizenship: "",
        homeowner: false,
        percentage: "",
      },
      personal: {},
      email: "",
      phone: "+1",
      firstname: "",
      middlename: "",
      lastname: "",
      taxId: "",
      information: "",
      imageFile: null,
      imageName: "",
      fontFamily: "null",
      requestedAmount: null,
      isMarketingLink: false,
      equipmentName: "",
      equipmentCost: "",
      referrerUrl: "",
      referrerEquipmentDescription: "",
      referrerEquipmentCost: null,
      referrerEquipmentImageUrl: "",
      referrerEquipmentMonthlyCost: null,
    },
    refreshApplications: false,
    prequalifiedOffers: [],
    informationSlug: "",
    applicationUUID: "",
    refreshApplicationLink: false,
    isLoggedIn: false,
    refreshProfile: false,
    signupResponse: "",
    user: {
      username: "",
      role: "",
      logo: "",
      themeColor: "",
      profilePicture: null,
      companyCategory: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      companyName: "",
      address: "",
      isShowOffers: false,
      isShowReports: false,
      isDefault: false,
    },
  },
  dispatch: (action) => {},
};
export const GlobalContext = createContext(values);
GlobalContext.displayName = "GlobalContext";
