import React, { useContext } from 'react'
import { useState } from 'react'
import { Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import axios from '../../../../../api/axios';
import Cookies from "universal-cookie";
import { GlobalContext } from '../../../../../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getBaseStyle, getHoverStyle } from '../../../../../utils/styles';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
const cookies = new Cookies();
const GenerateApiLink = ({ handleClose }) => {
    const [showAlert, setShowAlert] = useState('')
    const [errMsg, setErrMsg] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [equipmentName, setEquipmentName] = useState('');
    const [equipmentPrice, setEquipmentPrice] = useState('');
    const [equipmentUrl, setEquipmentUrl] = useState('');
    const [equipmentDescription, setEquipmentDescription] = useState('');
    const [equipmentUrlError, setEquipmentUrlError] = useState('')
    const [equipmentDescriptionError, setEquipmentDescriptionError] = useState('')
    const [validated, setValidated] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);
    const [isPriceValid, setIsPriceValid] = useState(false)
    const navigate = useNavigate();
    const { state, dispatch } = useContext(GlobalContext);
    const [buttonHover, setButtonHover] = useState(false)
    const baseColor = state?.user?.themeColor;
    const baseStyle = getBaseStyle(baseColor);
    const hoverStyle = getHoverStyle(baseColor);
    const [eqCategory, setEQCategory] = useState(state?.user?.companyCategory ?? "")
    const options = ['Construction', 'Ag', 'Transpo', 'Lifts', 'Other'];
    const Price_REGEX = /^\d{1,3}(,\d{3})*(\.\d{1,2})?$/;
    const URL_REGEX = new RegExp(
        '^(https?:\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i'
    );
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleClose2 = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
        setOpen2(false)
    };
    useEffect(() => {
        setIsPriceValid(Price_REGEX.test(equipmentPrice));
    }, [equipmentPrice]);
    const handleSignup = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false || eqCategory === "" || equipmentUrlError || equipmentDescriptionError) {
            e.stopPropagation();
            setValidated(true)
        }
        else {
            setValidated(true)
            setShowAlert('')
            setErrMsg('')
            setShowLoader(true)
            let body = {
                equipment_name: equipmentName,
                equipment_price: parseFloat(equipmentPrice.replace(/,/g, '')),
                equipment_category: eqCategory,
                equipment_url: equipmentUrl,
                equipment_description: equipmentDescription,
            }
            axios.post('lender/applicationlink/', body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            })
                .then(res => {
                    console.log("res.data", res.data)
                    setShowAlert('Application link created successfully')
                    setOpen2(true)
                    dispatch({ type: "REFRESH_APPLICATION_LINK", payload: !state?.refreshApplicationLink })
                    setTimeout(() => {
                        handleClose();
                    }, 1000)
                    setValidated(false)
                    setShowLoader(false)
                }).catch(err => {
                    setShowLoader(false)
                    if (!err?.response) {
                        setErrMsg('No Server Response');
                        setOpen(true)
                    } else if (err.response?.status === 400) {
                        if (err?.response?.data?.non_field_errors) {
                            setErrMsg(err?.response?.data?.non_field_errors)
                            setOpen(true)

                        }
                        else {
                            if (err.response?.data?.equipment_name) {
                                setErrMsg(err.response?.data?.equipment_name);
                            }
                            else if (err.response?.data?.equipment_url) {
                                setErrMsg(err.response?.data?.equipment_url);
                            }
                            setOpen(true)
                        }
                    } else if (err.response?.status === 401) {
                        console.log(err);
                        window.localStorage.setItem("isLoggedIn", "false");
                        navigate("/login");
                    } else {
                        setErrMsg(err.response?.data?.Error);
                        setOpen(true)
                    }
                })
        }

    }
    const handleInputFocus = () => {
        setInputFocused(true);
    };

    const handleInputBlur = () => {
        setInputFocused(false);
    };
    const handleOptionChange = (event) => {
        setEQCategory(event.target.value);
    };
    const handleUrlChange = (e) => {
        const url = e.target.value;
        setEquipmentUrl(url);
        if (URL_REGEX.test(url)){
            setEquipmentUrlError('');
        }
        else {
            setEquipmentUrlError('Please provide a valid URL');
        }
    };
    const handleDescriptionChange = (e) => {
        const description = e.target.value
        setEquipmentDescription(description)
        if (description) {
            const commaOccurences = (description.match(/,/g))
            if (commaOccurences) {
                if (commaOccurences.length === 2) {
                    setEquipmentDescriptionError("")
                }
                else {
                    setEquipmentDescriptionError("Please follow the provided format Year, Make, Model")
                }
            }
            else {
                setEquipmentDescriptionError("Please follow the provided format Year, Make, Model")
            }
        }
        else {
            setEquipmentDescriptionError("")
        }
    }
    return (
        <>
            {
                errMsg && (
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Alert
                            onClose={handleClose2}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {errMsg}
                        </Alert>
                    </Snackbar>
                )
            }
            {
                showAlert && (
                    <Snackbar
                        open={open2}
                        autoHideDuration={6000}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Alert
                            onClose={handleClose2}
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {showAlert}
                        </Alert>
                    </Snackbar>
                )
            }
            <div className="container mt-2 p-lg-5 p-md-5 p-4" >
                <Form noValidate validated={validated} className='text-center' onSubmit={handleSignup}>
                    {/* <div className={errMsg ? "alert alert-danger alert-dismissible fade show" : "offscreen"}>
                        {errMsg}
                        <button type="button" className={errMsg ? "btn-close" : "d-none"} onClick={() => setErrMsg('')}></button>
                    </div>
                    <div className={showAlert ? "alert alert-info alert-dismissible fade show" : "offscreen"}>
                        {showAlert}
                        <button type="button" className={showAlert ? "btn-close" : "d-none"} onClick={() => setShowAlert('')}></button>
                    </div> */}
                    <h4 className='mb-4'>Generate new application link</h4>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1">
                        <Form.Label className='Email' style={{ float: "left" }}>Equipment Name:</Form.Label>
                        <Form.Control
                            type="text"
                            autoComplete="off"
                            className="form-control mb-3"
                            value={equipmentName}
                            onChange={(e) => setEquipmentName(e.target.value)}
                            aria-describedby="uidnote"
                            minLength={3}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid equipment name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput2">
                        <Form.Label className='Email d-flex ' style={{ textAlign: "start" }}>Equipment Price:</Form.Label>

                        <div className={`generate-l
                        ${inputFocused && validated === false ?
                                'input-focus' : isPriceValid && inputFocused && !validated
                                    ? 'input-focus' : inputFocused && validated && isPriceValid === true ? 'valid-input-focus' : inputFocused && validated && isPriceValid === false && 'invalid-input-focus'} ${validated ? isPriceValid && equipmentPrice !== '' ? 'input-valid' : 'input-invalid' : ""}`}>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text sign-c">$</span>
                                </div>
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    className="form-control link-field"
                                    value={equipmentPrice}
                                    onFocus={handleInputFocus} // Add onFocus event handler
                                    onBlur={handleInputBlur}
                                    onChange={(e) => {
                                        const cleanedValue = e.target.value
                                            .replace(/,/g, '')
                                            .replace(/[^0-9.]/g, '');

                                        const parsedValue = parseFloat(cleanedValue);
                                        if (!isNaN(parsedValue)) {
                                            setEquipmentPrice(parsedValue.toLocaleString());
                                        } else {
                                            setEquipmentPrice('');
                                        }
                                    }}
                                    aria-describedby="uidnote"
                                    pattern="^\d{1,3}(,\d{3})*(\.\d{1,2})?$"
                                    required
                                />
                            </div>
                        </div>
                        <Form.Control.Feedback type="invalid" style={{ display: validated && equipmentPrice === "" ? "block" : "none" }}>
                            Please provide a valid equipment price.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput4">
                        <Form.Label className='Email' style={{ float: "left" }}>Equipment Category:</Form.Label>
                        <Form.Control required as="select" type="select" name="payment_method" onChange={handleOptionChange} value={eqCategory}>
                            <option value="">Select an option</option>
                            {options.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please choose a valid option.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput3">
                        <Form.Label className='Email' style={{ float: "left" }}>Equipment Url:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder='http://www.example.com'
                            value={equipmentUrl}
                            onChange={handleUrlChange}
                            isInvalid={validated && equipmentUrlError}
                        />
                        {validated && equipmentUrlError && (
                            <Form.Control.Feedback type="invalid">
                                {equipmentUrlError}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput3">
                        <Form.Label className='Email' style={{ float: "left" }}>Equipment Description:</Form.Label>
                        <Form.Control
                            type="text"
                            value={equipmentDescription}
                            onChange={handleDescriptionChange}
                            placeholder='(Year, Make, Model)'
                            isInvalid={validated && equipmentDescriptionError}
                            aria-describedby="uidnote"
                        />
                        {validated && equipmentDescriptionError && (
                            <Form.Control.Feedback type="invalid">
                                {equipmentDescriptionError}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <div className="text-center">
                        <button
                            className="btn btn-dark px-5"
                            type="submit"
                            onMouseEnter={() => setButtonHover(true)}
                            onMouseLeave={() => setButtonHover(false)}
                            style={state?.user?.role !== "Staff" ? {} : buttonHover ? hoverStyle : baseStyle}
                        >
                            {showLoader ? <Spinner style={{ width: "1.4rem", height: "1.4rem" }} /> : "Generate Link"}</button>
                    </div>
                </Form >
            </div >
        </>
    )
}

export default GenerateApiLink