import React from 'react'
import Sidebar from '../components/mainpage/subcomponent/Sidebar'
import '../components/styles/dashboard.css'
import Notcompleted from '../components/mainpage/subcomponent/notcompleted'
const MainNotCompleted = () => {
    return (
        <div>
            <Sidebar/>
            <div className='container-fluid'>
                <Notcompleted />
            </div>
        </div>

    )
}

export default MainNotCompleted