import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { AuthProvider } from "./components/logincomponent/subcomponent/context/AuthProvider";
import RequireAuth from "./components/logincomponent/subcomponent/context/RequireAuth";
import unProtectedRoutes from "./Routes/unprotected";
import protectedRoutes from "./Routes/protected";
import 'aos/dist/aos.css';
import AOS from 'aos';
import { useEffect } from "react";
import AppWrapper from "./AppWrapper";

function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (

    <BrowserRouter>
      <AuthProvider>
        <AppWrapper>
          <Routes>
            <Route element={<RequireAuth />}>
              {protectedRoutes?.map(el => <Route path={el?.path} element={el?.element} />)}
            </Route>
            {unProtectedRoutes?.map(elem => <Route path={elem?.path} element={elem?.element} />)}
          </Routes>
        </AppWrapper>
      </AuthProvider>
    </BrowserRouter>
  );
}
export default App;
