import React from 'react'
import '../../styles/dashboard.css';
import { Table } from 'react-bootstrap';
import { Delete, Edit } from '@mui/icons-material';
const UsersData = ({ data2, setEmailToDelete, setUsernameToDelete, setShowModal }) => {
    return (
        <>
            <Table className='table-backgorund'>
                <thead className='table-head'>
                    <tr>
                        <th>Email</th>
                        <th>Username</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data2?.map((item2, index) => (
                        <>
                            <div style={{ marginBottom: "0.5rem" }} key={index}></div>
                            <tr>
                                <td>{item2?.email}</td>
                                <td>{item2?.username}</td>
                                <td>
                                    {/* <Edit style={{ cursor: "pointer" }} className='mx-1' onClick={() => { console.log('edit') }} /> */}
                                    <Delete
                                        style={{ cursor: "pointer" }} className='mx-1'
                                        onClick={() => { setShowModal(true); setUsernameToDelete(item2?.username); setEmailToDelete(item2?.email); }}
                                    />
                                </td>
                            </tr>
                        </>
                    ))}


                </tbody>
            </Table>
        </>
    )
}

export default UsersData