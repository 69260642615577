import React, { useContext, useEffect, useRef, useState } from 'react'
import '../styles/login.css';
import { FaCircleUser } from "react-icons/fa6";
import { Form, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import { GlobalContext } from '../../context/GlobalContext';


const Signup = () => {
  const REGISTER_URL = '/auth/register/';
  const { state, dispatch } = useContext(GlobalContext)
  const emailRef = useRef();
  const usernameRef = useRef();

  const [email, setEmail] = useState('');

  const [username, setUsername] = useState('');

  const [password, setPassword] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [matchPassword, setMatchPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showValidationTick, setShowValidationTick] = useState(false);

  // Function to validate password
  const validatePassword = () => {
    const regex = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$');
    const isValid = regex.test(matchPassword) && matchPassword === password;
    setIsPasswordValid(isValid);
    // Only show the validation tick if the form has been validated and the password is valid
    setShowValidationTick(validated && isValid);
  };

  // Call the validatePassword function whenever matchPassword or password changes
  useEffect(() => {
    validatePassword();
  }, [matchPassword, password]);
  useEffect(() => {
    emailRef.current.focus();
  }, [])


  const handleSubmit = async (e) => {
    setErrMsg('');
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    }
    else if (password !== matchPassword) {
      e.stopPropagation();
      setValidated(true);
    }
    else {
      setValidated(true);
      setShowLoader(true)
      try {
        const response = await axios.post(REGISTER_URL, {
          username,
          email,
          password,
        },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          }
        );
        if (response?.data?.Error) {
          setErrMsg(response?.data?.Error)
          setShowLoader(false);
        } else {
          if (response?.data?.Success) {
            setSuccessMsg(response?.data?.Success)
            dispatch({
              type: 'SIGNUP_RES',
              payload: response?.data?.Success
            })
            setValidated(false)
            setShowLoader(false);
            navigate('/login')
          }
          setEmail('');
          setUsername('');
          setPassword('');
          setMatchPassword('');
          setShowLoader(false);
        }

      } catch (err) {
        console.log(err.response)
        if (!err?.response) {
          setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
          setErrMsg(Object.values(err.response?.data)[0]);
        }
        setShowLoader(false);
      }
    }

  }

  return (
    <div className='container main-square-box'>
      <div className='row main-square-box-h-login  d-flex justify-content-center' >
        <div className='col-lg-10 color shadow p-3 rounded-4'>
          <div className='centered-div mb-3'>
            <div className='form-div2'>
              <div className='Icon-div'>
                <FaCircleUser className='Icon'></FaCircleUser>
              </div>
              <div className={`${errMsg ? "alert alert-danger alert-dismissible fade show mt-3" : "offscreen"}`}>
                {errMsg}
                <button type="button" className={errMsg ? "btn-close" : "d-none"} onClick={() => setErrMsg('')}></button>
              </div>
              <div className={successMsg ? "alert alert-success alert-dismissible fade show mt-3" : "offscreen"}>
                {successMsg}
                <button type="button" className={successMsg ? "btn-close" : "d-none"} onClick={() => setSuccessMsg('')}></button>
              </div>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-2 mt-1" controlId="exampleForm.ControlInput1">
                  <Form.Label className='Email'>Email:</Form.Label>
                  <Form.Control
                    ref={emailRef}
                    type="email"
                    autoComplete="off"
                    className="form-control mb-1"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    aria-describedby="uidnote"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-2 mt-1" controlId="exampleForm.ControlInput2">
                  <Form.Label className='Email'>Username:</Form.Label>
                  <Form.Control
                    type="username"
                    ref={usernameRef}
                    autoComplete="off"
                    className="form-control mb-1"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    aria-describedby="uidnote"
                    pattern={'^.{4,}$'}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid username.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-2" controlId="exampleForm.ControlInput3">
                  <Form.Label className='Passward'>Password:</Form.Label>
                  <Form.Control
                    type="password"
                    className="form-control mb-1"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    aria-describedby="pwdnote"
                    pattern={'^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$'}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput4">
                  <Form.Label className='Password'>Confirm Password:</Form.Label>
                  <Form.Control
                    type="password"
                    className='form-control mb-2'
                    value={matchPassword}
                    onChange={(e) => setMatchPassword(e.target.value)}
                    required
                    aria-describedby="confirmnote"
                    pattern={'^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$'}
                    isInvalid={validated && !isPasswordValid}
                    isValid={showValidationTick}
                  />
                  <Form.Control.Feedback type="invalid">
                    {matchPassword !== password
                      ? "Passwords must match."
                      : "Please provide a valid password."}
                  </Form.Control.Feedback>
                </Form.Group>
                <button
                  id='btn-radiuss' type="submit" className="btn btn-primary mt-3">
                  {showLoader ? <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> : "Sign up"}
                </button>
              </Form>
              <p className='account-para mt-2'>Already Have an Account? <Link to="/login">Login</Link></p>


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup