import Question from "../components/questionscomponent/question";
import ForgotPassword from "../components/logincomponent/subcomponent/ForgotPassword"
import Login from "../components/logincomponent/login";
import Signup from "../components/signupcomponent/signup";
import PrequalifiedOffers from "../components/questionscomponent/subcomponent/PrequalifiedOffers";
const unProtectedRoutes = [
    {
        path: "/application/:link",
        element: <Question />
    },
    {
        path: "/reset-password/:token",
        element: <ForgotPassword />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/signup",
        element: <Signup />
    },
]
export default unProtectedRoutes;