import React, { useContext, useState } from 'react';
import '../../styles/dashboard.css'; // Import your CSS file for styling
import img6 from '../../../Images/image-3.webp'
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../logincomponent/subcomponent/context/AuthProvider';
import { useEffect, useRef } from 'react';
import axios from '../../../api/axios';
import Cookies from 'universal-cookie';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { GlobalContext } from '../../../context/GlobalContext';
const cookies = new Cookies();
const ProfileDropdown = () => {
  const{state,dispatch}=useContext(GlobalContext)
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const logout = () => {
    setAuth({});
    window.localStorage.setItem("isLoggedIn", "false");
    navigate('/login');
  };

  const handleSync = async () => {
    try {
      const response = await axios.post('lender/sync-data/', {},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${cookies.get('access')}`
          },
          withCredentials: true
        })
      console.log(response, 'sync response')
    } catch (err) {
      console.log(err, "sync error")
    }
  }

  // Close the dropdown when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="profile-dropdown" ref={dropdownRef}>
      <div className="profile-button" onClick={toggleDropdown}>
        {/* <img src={image} alt="logo" className="profile-image" /> */}
        <Stack direction="row" spacing={2}>
          <Avatar sx={{ bgcolor: '#0094FF' }}>{(state.user?.firstName && state?.user?.lastName) ? (`${state.user?.firstName.charAt(0).toUpperCase()}${state?.user?.lastName.charAt(0).toUpperCase()}`) : `${state.user.username.charAt(0)}`}</Avatar>
        </Stack>
      </div>
      {isOpen && (
        <div className="dropdown-content">
          <Link to="/profile">Profile</Link>
          {/* <Link to="" onClick={handleSync}>Sync Now</Link> */}
          <Link to="" onClick={logout}>Logout</Link>
        </div>
      )}
    </div>
  );
};


export default ProfileDropdown;
