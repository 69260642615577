import React from 'react'
import '../../styles/dashboard.css';
import Divider from '@mui/material/Divider';
import { CloseButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const PaynetSummary = ({
    reportSummary,
}) => {
    const navigate = useNavigate();

    function handleBackButtonClick() {
        navigate(-1);
    }
    return (
        <>
            <div className='row mt-4 mb-4'>
                <div className='col-lg-12 col-md-12'>
                    <div className='colorr shadow p-3 rounded-4'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <div className='h-dealing d-flex justify-content-between'>
                                    <h5>Paynet Report</h5>
                                    <CloseButton onClick={handleBackButtonClick} />
                                </div>
                                <div className='personal-info-div2 mt-3 p-3'>
                                    <p className='mb-1'>Average high credit per lender:<span className='deal-sumary'>{reportSummary?.avg_high_credit_per_lender}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>High credit:<span className='deal-sumary'>{reportSummary?.high_credit}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Inquires:<span className='deal-sumary'>{reportSummary?.inquires}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Last activity reported date:<span className='deal-sumary'>{reportSummary?.last_activity_reported_date}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Master score:<span className='deal-sumary'>{reportSummary?.master_score}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Number of active tradelines:<span className='deal-sumary'>{reportSummary?.number_of_active_tradelines}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Number of closed tradelines:<span className='deal-sumary'>{reportSummary?.number_of_closed_tradelines}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Oldest trade start date:<span className='deal-sumary'>{reportSummary?.oldest_trade_start_date}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Original receivable amount:<span className='deal-sumary'>{reportSummary?.original_receivable_amount}</span></p>
                                    <Divider className='p-divider' />
                                    <p className='mb-1'>Primary equipment type:<span className='deal-sumary'>{reportSummary?.primary_equipment_type}</span></p>
                                    <Divider className='p-divider' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default PaynetSummary