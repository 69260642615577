import React, { useContext, useState } from 'react'
import Sidebar from '../components/mainpage/subcomponent/Sidebar'
import '../components/styles/dashboard.css'
import Creditsummary from '../components/mainpage/subcomponent/creditsummary'
import Missing from '../Missing'
import { GlobalContext } from '../context/GlobalContext'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import axios from '../api/axios'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const MainSummary = () => {
    const { state, dispatch } = useContext(GlobalContext);
    const [pageNotFound, setPageNotFound] = useState(false)
    const [dealSummary, setDealSummary] = useState();
    const [personalInformation, setPersonalInformation] = useState();
    const [report, setReport] = useState();
    const { slug } = useParams();
    useEffect(() => {
        axios.get(`lender/list/credit-summary/?application=${slug}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${cookies.get('access')}`
            },
            withCredentials: true
        }).then(res => {
            console.log(res?.data)
            setDealSummary(res?.data?.deal_summary)
            setPersonalInformation(res?.data?.personal_information)
            setReport(res?.data?.report)
            dispatch({
                type: "INFORMATION_SLUG",
                payload: slug,
            });
        }).catch(err => {
            console.log(err, 'err')
            setPageNotFound(true)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            {pageNotFound ? <Missing /> : state?.informationSlug !== "" &&
                <>
                    <Sidebar />
                    <div className='container-fluid'>
                        <Creditsummary
                            dealSummary={dealSummary}
                            personalInformation={personalInformation}
                            report={report}
                        />
                    </div>
                </>}
        </div>
    )
}

export default MainSummary