import React from 'react';
import mazo from '../../../Images/mazo.jpg'
import '../../styles/applicationPdf.css'

const ApplicationPdf = ({ applicationDetails, signatureFile }) => {
    function foramtAddress(address) {
        return `${address?.house_number ?? ""} ${address?.street_name ?? ""}, ${address?.city ?? ""}, ${address?.state ?? ""} ${address?.zip_code ?? ""}, ${address?.country ?? ""}`
    }
    const signatureUrl = signatureFile
        ? URL.createObjectURL(signatureFile)
        : null;

    return (
        <div className='print'>
            <p style={{ paddingLeft: '16pt', textIndent: '0pt', textAlign: 'left' }}><span>
                <table border="0" cellspacing="0" cellpadding="0">
                    <tr>
                        <td>
                            <img src={mazo} width="166" height="56" />
                        </td>
                    </tr>
                </table>
            </span>
            </p>
            <p style={{ paddingLeft: '16pt', textIndent: '0pt', textAlign: 'left', fontSize: '25px' }}>CREDIT APPLICATION</p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <table style={{ borderCollapse: 'collapse', marginLeft: '7.6025pt', width: '95%' }} cellspacing="0">
                <tr style={{ height: '11pt' }}>
                    <td
                        style={{
                            width: '540pt',
                            border: '1pt solid #808080'
                        }}
                        colSpan="6"
                        bgcolor="#909294"
                    >
                        <p
                            className="s1"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            COMPANY INFORMATION
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td
                        style={{
                            width: '352pt',
                            borderTopStyle: 'solid !important',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid !important',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid !important',
                            borderBottomWidth: '1pt ',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid !important',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="4"
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Company Name: {applicationDetails?.company_name || ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '188pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="2"
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            E-mail:  {applicationDetails?.email || ''}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td
                        style={{
                            width: '249pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="3"
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Company Address: {applicationDetails?.company_address ? foramtAddress(applicationDetails?.company_address) : ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '103pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            City: {applicationDetails?.company_address ? applicationDetails?.company_address.city : ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '94pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            State:   {applicationDetails?.company_address ? applicationDetails?.company_address.state : ''}

                        </p>
                    </td>
                    <td
                        style={{
                            width: '94pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Zip: {applicationDetails?.company_address ? applicationDetails?.company_address.zip_code : ''}
                        </p>
                    </td>
                </tr>

                <tr style={{ height: '22pt' }}>
                    <td
                        style={{
                            width: '249pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="3"
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Signer:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '103pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Title:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '94pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Tel #: {applicationDetails?.phone_no || ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '94pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Federal Tax ID: {applicationDetails?.tax_id || ''}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '26pt' }}>
                    <td
                        style={{
                            width: '82pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '2pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                                border: 'none'
                            }}
                        >
                            Type of Business:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '67pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                        }}
                    >
                        <input type="checkbox" style={{ marginRight: '5pt' }} checked={applicationDetails?.entity_type === 'LLC' && true} readOnly />
                        <label className="s3" style={{ paddingLeft: '5pt' }}>LLC</label>
                        <br />
                        <input type="checkbox" style={{ marginRight: '5pt', marginTop: '2pt' }} checked={applicationDetails?.entity_type === "Other" && true} readOnly />
                        <label className="s3" style={{ paddingLeft: '5pt' }}>Other</label>
                    </td>
                    <td
                        style={{
                            width: '100pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <input type="checkbox" style={{ marginRight: '5pt' }} checked={applicationDetails?.entity_type === 'Sole Proprietorship' && true} readOnly />
                        <label className="s3" style={{ paddingLeft: '5pt' }} >Proprietorship</label>
                        <br />
                        <input type="checkbox" style={{ marginRight: '5pt', marginTop: '2pt' }} checked={applicationDetails?.entity_type === "Corporation" && true} readOnly />
                        <label className="s3" style={{ paddingLeft: '5pt' }}>Corporation</label>
                    </td>
                    <td
                        style={{
                            width: '103pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            # of Years in Business: {applicationDetails?.business_duration || ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '94pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Cell #:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '94pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Annual Revenue: {applicationDetails?.annual_business_revenue || ''}
                        </p>
                    </td>
                </tr>

                <tr style={{ height: '11pt' }}>
                    <td
                        style={{
                            width: '540pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="6"
                        bgcolor="#909294"
                    >
                        <p
                            className="s1"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            INFORMATION ON OFFICERS, PARTNERS OR GUARANTORS
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '11pt' }}>
                    <td
                        style={{
                            width: '177pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan={3}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            Name: {applicationDetails?.first_name || ''}{' '} {applicationDetails?.last_name || ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '119pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            Title:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '117pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            SSN: {applicationDetails?.ssn_number || ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '127pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '3pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            % Ownership: {applicationDetails?.ownership_percentage || ''}
                        </p>
                    </td>
                </tr>

                <tr style={{ height: '11pt' }}>
                    <td
                        style={{
                            width: '177pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="3"
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            Home Address: {applicationDetails?.home_address ? foramtAddress(applicationDetails?.home_address) : ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '119pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            City/State: {applicationDetails?.home_address ? applicationDetails?.home_address.city : ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '117pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            Zip: {applicationDetails?.home_address ? applicationDetails?.home_address.zip_code : ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '58pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '3pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            Date of Birth: {applicationDetails?.second_owner_date_of_birth || ''}
                        </p>
                    </td>

                </tr>
                <tr style={{ height: '11pt' }}>
                    <td
                        style={{
                            width: '177pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="3"
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            Name: {applicationDetails?.second_owner_first_name || ''}{' '} {applicationDetails?.second_owner_last_name || ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '119pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            Title:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '117pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            SSN: {applicationDetails?.second_owner_ssn_number || ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '127pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '3pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            % Ownership: {applicationDetails?.second_owner_ownership_percentage || ''}
                        </p>
                    </td>
                </tr>

                <tr style={{ height: '11pt' }}>
                    <td
                        style={{
                            width: '177pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="3"
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            Home Address:  {applicationDetails?.second_owner_home_address ? foramtAddress(applicationDetails?.second_owner_home_address) : ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '119pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            City/State: {applicationDetails?.second_owner_home_address ? applicationDetails.second_owner_home_address.city : ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '117pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            Zip: {applicationDetails?.second_owner_home_address ? applicationDetails.second_owner_home_address.zip_code : ''}
                        </p>
                    </td>
                    <td
                        style={{
                            width: '58pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '3pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            Date of Birth: {applicationDetails?.date_of_birth || ''}
                        </p>
                    </td>

                </tr>
                <tr style={{ height: '11pt' }}>
                    <td
                        style={{
                            width: '540pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="6"
                        bgcolor="#909294"
                    >
                        <p
                            className="s1"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            LEASE/LOAN REFERENCES
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '27pt' }}>
                    <td
                        style={{
                            width: '177pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="2"
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Name:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '92pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Original Amount:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '71pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Loan Acct. #:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '73pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Tel #:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '127pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '6pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Contact:
                        </p>
                    </td>
                </tr>

                <tr style={{ height: '28pt' }}>
                    <td
                        style={{
                            width: '177pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="2"
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Name:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '92pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Original Amount:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '71pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Loan Acct. #:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '73pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Tel #:
                        </p>
                    </td>
                    <td
                        style={{
                            width: '127pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                    >
                        <p
                            className="s2"
                            style={{
                                paddingLeft: '6pt',
                                textIndent: '0pt',
                                lineHeight: '11pt',
                                textAlign: 'left',
                            }}
                        >
                            Contact:
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '11pt' }}>
                    <td
                        style={{
                            width: '540pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="6"
                        bgcolor="#909294"
                    >
                        <p
                            className="s1"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                                border: '1px solid 909294'
                            }}
                        >
                            VENDOR INFORMATION
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '10pt' }}>
                    <td
                        style={{
                            width: '540pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="6"
                    >
                        <p style={{ textIndent: '0pt', textAlign: 'left', border: '1px solid 909294' }}>
                            <br />
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '11pt' }}>
                    <td
                        style={{
                            width: '540pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="6"
                        bgcolor="#909294"
                    >
                        <p
                            className="s1"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',

                            }}
                        >
                            EQUIPMENT DESCRIPTION (YEAR, MAKE, MODEL)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '22pt' }}>
                    <td
                        style={{
                            width: '540pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="6"
                    >
                        <p style={{ textIndent: '0pt', textAlign: 'left', border: '1px solid #909294' }}>
                            {applicationDetails?.marketing_equipment_name ? applicationDetails?.marketing_equipment_name : applicationDetails?.equipment_description}<br></br>
                        </p>
                    </td>
                </tr>


                <tr style={{ height: '11pt' }}>
                    <td
                        style={{
                            width: '540pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="6"
                        bgcolor="#909294"
                    >
                        <p
                            className="s1"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            EQUIPMENT COST
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '21pt' }}>
                    <td
                        style={{
                            width: '540pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="6"
                    >
                        <p style={{ textIndent: '0pt', textAlign: 'left', border: '1px solid #909294' }}>
                            {applicationDetails?.marketing_equipment_price ? applicationDetails?.marketing_equipment_price : applicationDetails?.equipment_price}<br></br>
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '11pt' }}>
                    <td
                        style={{
                            width: '540pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="6"
                        bgcolor="#909294"
                    >
                        <p
                            className="s1"
                            style={{
                                paddingLeft: '4pt',
                                textIndent: '0pt',
                                lineHeight: '10pt',
                                textAlign: 'left',
                            }}
                        >
                            DECLARATION
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '201pt' }}>
                    <td
                        style={{
                            width: '540pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '2pt',
                            borderTopColor: '#808080',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '2pt',
                            borderLeftColor: '#808080',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '2pt',
                            borderBottomColor: '#808080',
                            borderRightStyle: 'solid',
                            borderRightWidth: '2pt',
                            borderRightColor: '#808080',
                        }}
                        colSpan="6"
                    >
                        <p
                            className="s3"
                            style={{
                                paddingLeft: '1pt',
                                paddingRight: '4pt',
                                textIndent: '0pt',
                                textAlign: 'justify',

                            }}
                        >
                            The individual(s) signing below and any person for which information is provided affirmatively consents and agrees: (i) that I/we hereby represent that I/we am/are authorized to provide all information submitted and hereby provide my/our written instructions to Mazo Capital Solutions and its affiliates, assigns, successors and lending partners (collectively "Mazo Capital Solutions") to obtain information concerning the applicant and me/us from any company, entity, organization, government agency (including law enforcement) or business, including my/our consumer and business credit report(s), employment information, business dealings and trade references; (ii) that Mazo Capital Solutions may use and share any submitted and gathered information from or about the applicant and its owners or principals with lenders for consideration for a credit product, including any loan or lease or to finance the purchase of equipment; (iii) that all information provided is true, accurate and complete; (iv) that Mazo Capital Solutions may initially and subsequently obtain information from my/our consumer or business credit report(s) and otherwise in order to: (a) confirm my/our identity; (b) avoid fraudulent transactions in my/our name; (c) to evaluate my/our and any applicant’s initial and continuing creditworthiness; (d) to update, renew, confirm, extend, supplement, enforce and collect on any credit transaction resulting from this application; and
                        </p>
                        <p className="s3" style={{ paddingLeft: '1pt', textIndent: '0pt', textAlign: 'left' }}>
                            (e) that such inquiries may appear on my/our credit reports; (v) I/we waive any right or claim under the Fair Credit Reporting Act, the Drivers' Policy Protection Act and the Gramm-Leach-Bliley Act arising in the absence of this continuing consent; and (vi) to receive any notices required by law electronically to the electronic mail address(es) and or mobile telephone numbers provided. The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against credit applicants on the basis of race, color, religion, national origin, sex, marital status or age (provided the applicant has the capacity to enter into the binding contract); because all or part of the applicant’s income derives from any public assistance program; or because the applicant has in good faith exercised any right under the Consumer Credit Protection Act. If for any reason your application for business credit is denied, you have the right to a written statement of the specific reasons for the denial. To obtain the statement, please write to Mazo Capital Solutions, 300 Spectrum Center Drive, Suite 1070, Irvine, CA 92618 within 60 days from the date you are notified of our decision. We will send you a written statement of reasons for the denial of credit within 30 days of receiving your request for the statement. If you have provided Mazo Capital Solutions with a mobile telephone number, above or otherwise, you expressly consent to receive SMS text messages and telephone calls related to your application, any resulting transaction as well as promotional and marketing messages. You may opt out at any time by responding to any message we send with STOP, UNSUBSCRIBE, CANCEL, END or QUIT, after which we may send you a message confirming you have opted out, or requesting no
                        </p>
                        <p className="s3" style={{ paddingLeft: '1pt', textIndent: '0pt', lineHeight: '8pt', textAlign: 'left' }}>
                            further telephone calls. You may opt in again by replying to any previous message with START.
                        </p>
                    </td>
                </tr>

            </table>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <table style={{ borderCollapse: 'collapse', marginLeft: '5.84pt', width: '90%' }} cellspacing="0">
                <tr style={{ height: '34pt' }}>
                    <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', display: 'flex', alignItems: 'center' }}>
                        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left', fontSize: '9pt' }}>Signature</p>
                        {signatureFile ? <img src={signatureUrl} alt="Signature" /> : <img src={applicationDetails?.signature} alt="Signature" />}
                    </td>
                    <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt' }}>
                        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left', fontSize: '9pt' }}>Date {applicationDetails?.created_at}</p>
                    </td>
                    <td style={{}}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '33pt' }}>
                    <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt' }}>
                        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left', fontSize: '9pt' }}>Applicant</p>
                    </td>
                    <td style={{}}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{}}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{}}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '11pt' }}>
                    <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt' }}>
                        <p style={{ paddingLeft: '5pt', textIndent: '0pt', lineHeight: '9pt', textAlign: 'left', fontSize: '9pt' }}>Title</p>
                    </td>
                    <td style={{}}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{}}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
            </table>

        </div>
    )
}

export default ApplicationPdf;