import React, { useState, useMemo, useCallback, useContext } from 'react';
import '../../styles/dashboard.css'; // Import your CSS file for styling
import { GlobalContext } from '../../../context/GlobalContext';
import { getBaseStyle, getHoverStyle } from '../../../utils/styles';

const ToggleButton = ({ type, setType }) => {
  const [buttonHover, setButtonHover] = useState(false);
  const { state } = useContext(GlobalContext);
  const baseColor = state?.user?.themeColor;
  const baseStyle = useMemo(() => getBaseStyle(baseColor), [baseColor]);
  const hoverStyle = useMemo(() => getHoverStyle(baseColor), [baseColor]);

  const handleMouseEnter = useCallback(() => {
    setButtonHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setButtonHover(false);
  }, []);

  const handleOptionChange = useCallback(
    (option) => {
      setType(option);
    },
    [setType]
  );

  const getButtonStyle = () => {
    if (state?.user?.role === 'Staff' && type === 'week') {
      return buttonHover ? hoverStyle : baseStyle;
    }
    if (state?.user?.role === 'Staff' && type === 'month') {
      return buttonHover ? hoverStyle : baseStyle;
    }
    if (state?.user?.role === 'Staff' && type === 'year') {
      return buttonHover ? hoverStyle : baseStyle;
    }
    return {};
  };

  return (
    <div className="toggle-button-container">
      {['week', 'month', 'year'].map((buttonType) => (
        <button
          key={buttonType}
          className={`toggle-button ${type === buttonType ? 'active' : ''}`}
          onClick={() => handleOptionChange(buttonType)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={type === buttonType ? getButtonStyle() : {}}
        >
          {buttonType.charAt(0).toUpperCase() + buttonType.slice(1)}
        </button>
      ))}
    </div>
  );
};

export default ToggleButton;