import React, { useContext, useEffect } from 'react';
import { GlobalContext } from './context/GlobalContext'
import axios from './api/axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const AppWrapper = ({ children }) => {
    const { state, dispatch } = useContext(GlobalContext)
    const isLoggedIn = state.isLoggedIn;
    const fetchUserInfo = async () => {
        try {
            const response = await axios.get('auth/user/profile/',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`,
                    },
                    withCredentials: true
                });
            const data = await response?.data;
            return data;
        } catch (error) {
            console.error('Error fetching products:', error.message);
            if (error?.response?.status === 401) {
                dispatch({
                    type: "IS_LOGGED_IN",
                    payload: false
                })
                window.localStorage.setItem("isLoggedIn", "false")
            }
        }
    }
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const userInfo = await fetchUserInfo();
                if (userInfo) {
                    let obj = { ...state.user }
                    obj.username = userInfo?.username;
                    obj.isShowOffers = userInfo?.is_show_offers
                    obj.role = userInfo?.role;
                    obj.logo = userInfo?.company_logo ?? "";
                    obj.themeColor = userInfo?.theme_color !== "" && userInfo?.theme_color !== undefined ? userInfo?.theme_color : "#ffffff";
                    obj.profilePicture = userInfo?.profile_picture ?? null;
                    obj.companyCategory = userInfo?.company_category ? userInfo?.company_category : userInfo?.equipment_category ? userInfo?.equipment_category : "";
                    obj.address = userInfo?.address ?? "";
                    obj.firstName = userInfo?.first_name ?? "";
                    obj.lastName = userInfo?.last_name ?? "";
                    obj.email = userInfo?.email ?? "";
                    obj.phoneNo = userInfo?.phone_no ?? "";
                    obj.companyName = userInfo?.company_name ?? "";
                    obj.isShowReports = userInfo?.isShowReports ?? "";
                    dispatch({ type: 'USER_INFO', payload: obj });
                } else {
                    console.error('Failed to fetch user info.');
                }
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        if (isLoggedIn !== "false" && isLoggedIn !== false) {
            fetchProfileData();
        }
    }, [dispatch, isLoggedIn, state.refreshProfile])
    return <>{children}</>;
}

export default AppWrapper;
