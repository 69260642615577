import React, { useEffect, useState } from 'react'
import '../../styles/dashboard.css';
import Dealtable from './dealtable';
import Divider from '@mui/material/Divider';
import transparent from '../../../Images/transparent.webp'
import axios from '../../../api/axios';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import image from '../../../Images/Frame-31.webp';
import { useContext } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { getBaseStyle, getHoverStyle } from '../../../utils/styles';
const cookies = new Cookies();

const Offerpage = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [dealSummary, setDealSummary] = useState();
    const [offers, setOffers] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState(0);
    const [refresh, setRefresh] = useState(false)
    const [buttonHover, setButtonHover] = useState(0)
    const { state } = useContext(GlobalContext)
    const baseColor = state?.user?.themeColor;
    const baseStyle = getBaseStyle(baseColor);
    const hoverStyle = getHoverStyle(baseColor);

    useEffect(() => {
        axios.get(`lender/list/offers/?application=${slug}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${cookies.get('access')}`
            },
            withCredentials: true
        }).then(res => {
            console.log(res?.data)
            setDealSummary(res?.data?.deal_summary)
            setOffers(res?.data?.your_prequalified_offers)
            setSelectedOffer(res?.data?.selected_offer)
        }).catch(err => {
            if (err?.response?.status === 401) {
                console.log(err);
                window.localStorage.setItem("isLoggedIn", "false");
                navigate("/login");
            }
        })
    }, [refresh])
    function HandleSelectOffer(id) {
        let body = {
            application: slug,
            selected_offer: id
        }
        axios.put("lender/list/deal-details/", body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${cookies.get('access')}`
            },
            withCredentials: true
        }).then(res => {
            console.log(res?.data)
            setRefresh(!refresh)
        }).catch(err => {
            if (err?.response?.status === 401) {
                console.log(err);
                window.localStorage.setItem("isLoggedIn", "false");
                navigate("/login");
            }
        })
    }
    return (
        <>
            <div className='row mt-4 mb-4'>
                <div className='col-lg-12 col-md-12'>
                    <div className='colorr shadow p-3 rounded-4 offerpage-height'>
                        <div className='row'>
                            {/* <div className='col-lg-12'>
                                <div className='Alert'>
                                    <p className='p-2'>You can always <span className='p-1'><button type="submit" className="go-back-btn">Go back</button></span> to the previous page and increase your down payment to qualify for more options</p>
                                </div>
                            </div> */}
                            <div className='deal-summary mt-3'>
                                <h5>Deal Summary</h5>

                                <div className='App mt-4'>
                                    <Dealtable data3={dealSummary} />
                                </div>


                            </div>

                            <div className='prequalified mt-3 mb-4'>
                                <h5>Your prequalified offers:</h5>
                                <div className='mt-3'>
                                    <div className='row mob-cards-gap'>

                                        {offers?.length > 0
                                            ? offers?.map((elem, index) => {
                                                return (
                                                    <div className='col-lg-4 col-md-4 mb-4' key={index} style={{ borderRadius: "1rem" }}>
                                                        <div className="card hover-deals" style={{ borderRadius: "1rem" }}>
                                                            <div className="card-body main-card b-color" onClick={() => HandleSelectOffer(elem?.id)}
                                                                style={{ backgroundColor: selectedOffer === elem?.id ? "rgba(229, 244, 255, 1)" : "white", borderRadius: "1rem" }} >
                                                                <div className='d-flex justify-content-end mb-3'>
                                                                    <img src={transparent} className='dark-img' alt="logo" />
                                                                </div>

                                                                <div className='tiers'>
                                                                    <Divider className='divider' />
                                                                    <p className='para mt-1'>Monthly Payment <span className='values'>{elem?.monthly_payment}</span></p>
                                                                    <Divider className='divider' />
                                                                    <p className='para mt-1'>Financing Amount <span className='values'>{elem?.financing_amount}</span></p>
                                                                    <Divider className='divider' />
                                                                    <p className='para mt-1'>Total Down Payment <span className='values'>{elem?.total_down_payment}</span></p>
                                                                    <Divider className='divider' />
                                                                    <p className='para mt-1'>Term<span className='values'>{elem?.term}</span></p>
                                                                    <Divider className='divider' />
                                                                    <p className='para mt-2 app-only'>Submission requirements: App only</p>
                                                                    <div className='tier-btn'>
                                                                        <button
                                                                            type="submit"
                                                                            className={`t-btn values`}
                                                                            onMouseEnter={() => setButtonHover(index + 1)}
                                                                            onMouseLeave={() => setButtonHover(0)}
                                                                            style={state?.user?.role !== "Staff" ?
                                                                                { backgroundColor: selectedOffer === elem?.id ? "rgba(0, 148, 255, 0.1)" : "", color: selectedOffer === elem?.id ? "rgba(0, 148, 255, 1)" : "" }
                                                                                : selectedOffer === elem?.id ? { backgroundColor: "rgba(0, 148, 255, 0.1)", color: baseColor } : buttonHover === index + 1 ? hoverStyle : baseStyle}
                                                                        >
                                                                            {selectedOffer === elem?.id ? "selected" : "select"}
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className='no-data'>
                                                <img src={image} alt="logo" className='data-display' />
                                            </div>
                                        }

                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div >


        </>
    )
}

export default Offerpage