import React from 'react'
import '../styles/dashboard.css';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Sidebar from './subcomponent/Sidebar';
import Dashboard from './subcomponent/Dashboard';
import { useEffect } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { useContext } from 'react';
import { useState } from 'react';
import LoadingBar from 'react-top-loading-bar'

const MainApp = () => {
    const { state } = useContext(GlobalContext)
    const [progress, setProgress] = useState(30);
    const [showPage, setShowPage] = useState(false);
    useEffect(() => {
        if (state?.user?.username) {
            setShowPage(true);
            setProgress(100);
        }
    }, [state?.user])
    return (
        <><LoadingBar
            color="rgba(0, 148, 255, 1)"
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />

            <div className=''>
                <Sidebar />
                {showPage && (
                    <div className='container-fluid dashborad-background'>
                        <Dashboard />
                    </div>
                )}
            </div>
        </>
    )
}

export default MainApp


