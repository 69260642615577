import React, { useState } from 'react'
import '../../styles/dashboard.css';
import ReactApexChart from 'react-apexcharts';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Form } from 'react-bootstrap';
import ToggleButton from './tooglebtn';
import DataTable from './DataTable';
import { useEffect } from 'react';
import axios from '../../../api/axios'
import Cookies from 'universal-cookie';
import image from '../../../Images/Frame-31.webp'
const cookies = new Cookies();
const Dashboard = () => {
    const navigate2 = useNavigate();
    const [recentApplications, setRecentApplications] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [sidePriceSections, setSidePriceSections] = useState()
    const [selectedStatus, setSelectedStatus] = useState("");
    const [datesArray, setDatesArray] = useState([])
    const [graphData, setGraphData] = useState([])
    const [datesPipelineArray, setDatesPipelineArray] = useState([])
    const [graphPipelineData, setGraphPipelineData] = useState([])
    const [type, setType] = useState("month")
    useEffect(() => {
        // Get today's date
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');

        // Set today's date to toDate
        setToDate(`${yyyy}-${mm}-${dd}`);

        // Calculate a week ago from today
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

        const yyyyWeekAgo = oneWeekAgo.getFullYear();
        const mmWeekAgo = String(oneWeekAgo.getMonth() + 1).padStart(2, '0');
        const ddWeekAgo = String(oneWeekAgo.getDate()).padStart(2, '0');

        // Set a week ago from today to fromDate
        setFromDate(`${yyyyWeekAgo}-${mmWeekAgo}-${ddWeekAgo}`);
    }, []);
    useEffect(() => {
        if (fromDate !== "" && toDate !== "") {
            axios.get(`lender/dashboard/applications-graph/?starting_date=${fromDate}&ending_date=${toDate}&status=${selectedStatus}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${cookies.get("access")}`,
                },
                withCredentials: true,
            }).then(res => {
                setDatesArray(res?.data?.xaxis?.categories)
                setGraphData(res?.data?.data)
            }).catch(err => {

            })
        }
    }, [fromDate, toDate, selectedStatus])
    useEffect(() => {
        axios.get(`lender/dashboard/applications-pipeline/?type=${type}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${cookies.get("access")}`,
            },
            withCredentials: true,
        }).then(res => {
            setDatesPipelineArray(res?.data?.xaxis?.categories)
            setGraphPipelineData(res?.data?.series)
        }).catch(err => {

        })
    }, [type])
    useEffect(() => {
        axios.get('lender/dashboard/applications-sent/', {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${cookies.get("access")}`,
            },
            withCredentials: true,
        }).then(res => {
            setSidePriceSections(res?.data?.applications_sent)
        }).catch(err => {

        })
    }, [])
    useEffect(() => {
        axios.get('lender/dashboard/recent-applications/', {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${cookies.get("access")}`,
            },
            withCredentials: true,
        }).then(res => {
            setRecentApplications(res.data)
        }).catch(err => {

        })
    }, [])
    ////////////////////card////////////////////////////
    const card1 = {

        series: [{
            data: [1, 9, 7, 10]
        }],
        options: {
            chart: {
                width: '10%',
                type: 'area',
                height: '60',
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                opacity: 0.3
            },

            xaxis: {
                crosshairs: {
                    width: 1
                },
            },
            yaxis: {
                show: false,
            },
        }

    }


    const card2 = {

        series: [{
            data: [5, 10, 2, 20]
        }],
        options: {
            colors: ["rgba(0, 188, 139, 1)"],
            chart: {
                type: 'area',
                height: '60',
                width: '100%',
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                opacity: 0.3
            },

            xaxis: {
                crosshairs: {
                    width: 1
                },
            },
            yaxis: {
                show: false,
            },
        }

    }


    const card3 = {

        series: [{
            data: [4, 20, 10, 30]
        }],
        options: {
            colors: ["rgba(255, 184, 0, 1)"],
            chart: {
                type: 'area',
                height: '60',
                width: '100%',
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                opacity: 0.3
            },

            xaxis: {
                crosshairs: {
                    width: 1
                },
            },
            yaxis: {
                show: false,
            },
        }

    }

    const card4 = {

        series: [{
            data: [3, 30, 10, 50]
        }],
        options: {
            colors: ["rgba(141, 125, 253, 1)"],
            chart: {
                type: 'area',
                height: '60',
                width: '100%',
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                opacity: 0.3
            },

            xaxis: {
                crosshairs: {
                    width: 1
                },
            },
            yaxis: {
                show: false,
            },
        }

    }
    ////////////////////Line Chart//////////////////////////

    const chart = {

        series: [{
            name: "Applications",
            data: graphData
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
            markers: {
                size: 6,

                hover: {
                    size: 9
                }
            },

            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            yaxis: {
                min: 0,
                labels: {
                    formatter: (value) => { return parseInt(value).toLocaleString() }
                }
            },
            xaxis: {
                categories: datesArray,
            }
        },


    };

    ////////////////////Bar Chart///////////////////////////
    const state = {
        series: [{
            name: graphPipelineData[0]?.name,
            data: graphPipelineData[0]?.data
        }, {
            color: 'rgba(255, 159, 47, 1)',
            name: graphPipelineData[1]?.name,
            data: graphPipelineData[1]?.data
        }],
        options: {
            chart: {

                type: 'bar',
                height: 350

            },
            plotOptions: {
                bar: {

                    horizontal: false,
                    columnWidth: '40%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {

                show: true,
                width: 2,
                colors: ['transparent']
            }, yaxis: {
                // min: 0,
                labels: {
                    formatter: (value) => { return parseInt(value).toLocaleString() }
                }
            },
            xaxis: {
                categories: datesPipelineArray,
            },
            fill: {
                colors: ['rgba(0, 148, 255, 1)', 'rgba(255, 159, 47, 1)'],
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$" + parseInt(val).toLocaleString()
                    }
                }
            }
        },
        ////////////////// Bar Chart End/////////////////////////////////

    };
    return (
        <>
            <div className='row mt-1'>
                <div className=' col-lg-12 col-md-12 nav p-2'>
                    <h4 className='p-1 dashboard'>Dashboard</h4>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-3 col-md-5'>
                    <div className='colorr shadow p-3 rounded-4 dashborad-card-height side-bar-graph'>
                        <div className='all-card '>
                            <Card className='set-border '>
                                <Card.Body className='set-card'>
                                    <div className='d-flex p-2'>
                                        <div className='dollar-logo'>
                                            <div className="stat dollar-logo"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="align-middle text-white"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></div>
                                        </div>
                                        <div className="d-flex align-items-center manage-line">
                                            <div className="flex-grow-1 mt-2 ms-2">
                                                <h4 className="figers">${sidePriceSections?.total_applications_sent?.toLocaleString()}</h4>
                                                <p className='title-para'>Total Applications Sent</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="chart" className='mt-1'>
                                        <ReactApexChart options={card1.options} series={card1.series} type="area" height={70} width={'100%'} border-radius={'10%'} />
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card className='set-border mt-4 hovery-effect' onClick={() => navigate2("/applications/funded?page_no=1")}>
                                <Card.Body className='set-card'>
                                    <div className='d-flex p-2'>
                                        <div className='dollar-logo'>
                                            <div className="stat2 dollar-logo"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="align-middle text-white"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></div>
                                        </div>
                                        <div className="d-flex align-items-center manage-line">
                                            <div className="flex-grow-1 mt-2 ms-2">
                                                <h4 className="figers">${sidePriceSections?.total_applications_funded?.toLocaleString()}</h4>
                                                <p className='title-para'>Total Applications Completed</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="chart" className='mt-1'>
                                        <ReactApexChart options={card2.options} series={card2.series} type="area" height={70} />
                                    </div>

                                </Card.Body>
                            </Card>
                            <Card className='set-border mt-4 hovery-effect ' onClick={() => navigate2("/applications/in-review?page_no=1")}>
                                <Card.Body className='set-card'>
                                    <div className='d-flex p-2'>
                                        <div className='dollar-logo'>
                                            <div className="stat3 dollar-logo"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="align-middle text-white"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></div>
                                        </div>
                                        <div className="d-flex align-items-center manage-line">
                                            <div className="flex-grow-1 mt-2 ms-2">
                                                <h4 className="figers">${sidePriceSections?.total_applications_in_review?.toLocaleString()}</h4>
                                                <p className='title-para'>Total Applications In Review</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="chart" className='mt-1'>
                                        <ReactApexChart options={card3.options} series={card3.series} type="area" height={70} />
                                    </div>

                                </Card.Body>
                            </Card>
                            <Card className='set-border mt-4 hovery-effect' onClick={() => navigate2("/applications/approved?page_no=1")}>
                                <Card.Body className='set-card'>
                                    <div className='d-flex p-2'>
                                        <div className='dollar-logo'>
                                            <div className="stat4 dollar-logo"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="align-middle text-white"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></div>
                                        </div>
                                        <div className="d-flex align-items-center manage-line">
                                            <div className="flex-grow-1 mt-2 ms-2">
                                                <h4 className="figers">${sidePriceSections?.total_applications_approved?.toLocaleString()}</h4>
                                                <p className='title-para'>Total Applications Approved</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="chart" className='mt-1'>
                                        <ReactApexChart options={card4.options} series={card4.series} type="area" height={70} />
                                    </div>

                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
                <div className='col-lg-9 col-md-7'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='colorr shadow p-3 rounded-4 line-c-height'>
                                <div id="chart">
                                    <div className='Funded'>
                                        <h5>Applications</h5>
                                        <div className='ms-auto all-apprroved'>
                                            <div className='d-lg-flex flex-lg-row mx-3 d-md-flex flex-md-column gap-3 '>
                                                <Form.Group className='d-flex mx-2'>
                                                    <Form.Label className='mx-2 mt-2'>From</Form.Label>
                                                    <Form.Control
                                                        type='date' value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className='d-flex mx-2'>
                                                    <Form.Label className='mx-2 mt-2'>To</Form.Label>
                                                    <Form.Control
                                                        type='date' value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className='d-flex mx-2'>
                                                    <Form.Label className='mx-2 mt-2'>Status</Form.Label>
                                                    <Form.Select aria-label="Default select example" defaultValue={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                                                        <option value="">All</option>
                                                        <option value="Not Completed">Not Completed</option>
                                                        <option value="In Review">In Review</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Contracts Out">Contracts Out</option>
                                                        <option value="Contracts In">Contracts In</option>
                                                        <option value="Funded">Funded</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    <ReactApexChart options={chart.options} series={chart.series} type="area" height={300} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-lg-12'>
                            <div className='colorr shadow p-3 rounded-4  bar-c-height'>
                                <div id="chart">
                                    <div className='Funded'>
                                        <h5>Funded Volume</h5>
                                        <div className='ms-auto buttons-background'>
                                            <ToggleButton type={type} setType={setType} />
                                        </div>
                                    </div>
                                    <ReactApexChart options={state.options} series={state.series} type="bar" height={250} />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div className='row mt-3 mb-4'>
                <div className='col-lg-12 col-md-12'>
                    <div className='colorr shadow p-3 rounded-4'>
                        <h5 className='p-1 dashboard'>Recent Applications</h5>
                        <div className="App mt-3">
                            {recentApplications.length > 0 ?
                                <DataTable data={recentApplications} />
                                :
                                <div className='d-flex justify-content-center m-5'>
                                    <img src={image} alt="logo" width="50" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard