import React from "react";
const Email = React.lazy(() => import("./subcomponent/Email"));
const Username = React.lazy(() => import("./subcomponent/Username"));
const Middlename = React.lazy(() => import("./subcomponent/Middlename"));
const Lastname = React.lazy(() => import("./subcomponent/Lastname"));
const Companyname = React.lazy(() => import("./subcomponent/companyname"));
const Longbussiness = React.lazy(() => import("./subcomponent/longbussiness"));
const Entitytype = React.lazy(() => import("./subcomponent/entitytype"));
const Purchase = React.lazy(() => import("./subcomponent/purchase"));
const BussinessAnual = React.lazy(() =>
  import("./subcomponent/bussinessAnual")
);
const TaxId = React.lazy(() => import("./subcomponent/taxId"));
const Industry = React.lazy(() => import("./subcomponent/industry"));
const Dob = React.lazy(() => import("./subcomponent/dob"));
const Address = React.lazy(() => import("./subcomponent/address"));
const Phone = React.lazy(() => import("./subcomponent/phone"));
const Ownership = React.lazy(() => import("./subcomponent/Ownership"));
const Homeowner = React.lazy(() => import("./subcomponent/homeowner"));
const SignatureComponent = React.lazy(() =>
  import("./subcomponent/SignatureComponent")
);
const Information = React.lazy(() => import("./subcomponent/ThanksComponent"));
const GetStarted = React.lazy(() => import("./subcomponent/GetStarted"));
const PrequalifiedOffers = React.lazy(() =>
  import("./subcomponent/PrequalifiedOffers")
);
const Confirmation = React.lazy(() => import("./subcomponent/Confirmation"));
const EquipmentUse = React.lazy(() => import("./subcomponent/EquipmentUse"));
const MonthlyIncome = React.lazy(() => import("./subcomponent/MonthlyIncome"));
const RequestedAmount = React.lazy(() =>
  import("./subcomponent/RequestedAmount")
);
const SecondOwnerEmail = React.lazy(() =>
  import("./subcomponent/SecondOwnerEmail")
);
const SecondOwnerPhone = React.lazy(() =>
  import("./subcomponent/SecondOwnerPhone")
);
const SecondOwnerFirstName = React.lazy(() =>
  import("./subcomponent/SecondOwnerFirstName")
);
const SecondOwnerLastName = React.lazy(() =>
  import("./subcomponent/SecondOwnerLastName")
);
const SecondOwnerDob = React.lazy(() =>
  import("./subcomponent/SecondOwnerDOB")
);
const SecondOwnerSSN = React.lazy(() =>
  import("./subcomponent/SecondOwnerSSN")
);
const SecondOwnerHomeAddress = React.lazy(() =>
  import("./subcomponent/SecondOwnerHomeAddress")
);
const EquipmentName = React.lazy(() => import("./subcomponent/EquipmentName"));
const EquipmentCost = React.lazy(() => import("./subcomponent/EquipmentCost"));
const Suffix = React.lazy(() => import("./subcomponent/personal/Suffix"));
const Ssn = React.lazy(() => import("./subcomponent/Ssn"));
const ResidentialAddress = React.lazy(() =>
  import("./subcomponent/personal/ResidentialAddress")
);
const Living = React.lazy(() => import("./subcomponent/personal/Living"));
const EmploymentStatus = React.lazy(() =>
  import("./subcomponent/personal/EmploymentStatus")
);
const EmployerDetails = React.lazy(() =>
  import("./subcomponent/personal/EmployerDetails")
);
const RetirementDetails = React.lazy(() =>
  import("./subcomponent/personal/RetirementDetails")
);
const EmploymentDetails = React.lazy(() =>
  import("./subcomponent/personal/EmploymentDetails")
);
const EmployerAddress = React.lazy(() =>
  import("./subcomponent/personal/EmployerAddress")
);
const AdditionalIncome = React.lazy(() =>
  import("./subcomponent/personal/AdditionalIncome")
);
const CoApplicant = React.lazy(() =>
  import("./subcomponent/personal/CoApplicant")
);
const PurchaseDetails = React.lazy(() =>
  import("./subcomponent/personal/PurchaseDetails")
);
const FinancingDetails = React.lazy(() =>
  import("./subcomponent/personal/FinancingDetails")
);
const CoApplicantEmail = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantEmail")
);
const CoApplicantPhone = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantPhone")
);
const CoApplicantFirstName = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantFirstName")
);
const CoApplicantMiddleName = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantMiddleName")
);
const CoApplicantLastName = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantLastName")
);
const CoApplicantDob = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantDob")
);
const CoApplicantSsn = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantSsn")
);
const CoApplicantSuffix = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantSuffix")
);
const CoApplicantResidentialAddress = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantResidentialAddress")
);
const CoApplicantLiving = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantLiving")
);
const CoApplicantEmploymentStatus = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantEmploymentStatus")
);
const CoApplicantRetirementDetails = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantRetirementDetails")
);
const CoApplicantEmployerAddress = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantEmployerAddress")
);
const CoApplicantEmploymentDetails = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantEmploymentDetails")
);
const CoApplicantEmployerDetails = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantEmployerDetails")
);
const CoApplicantAdditionalIncome = React.lazy(() =>
  import("./subcomponent/personal/CoApplicantAdditionalIncome")
);

const components = {
  GetStarted,
  RequestedAmount,
  Email,
  Username,
  Middlename,
  Lastname,
  EquipmentUse,
  Companyname,
  Longbussiness,
  Entitytype,
  Purchase,
  BussinessAnual,
  TaxId,
  Industry,
  Dob,
  MonthlyIncome,
  Address,
  Phone,
  Ownership,
  Homeowner,
  SignatureComponent,
  Information,
  PrequalifiedOffers,
  Confirmation,
  SecondOwnerEmail,
  SecondOwnerPhone,
  SecondOwnerFirstName,
  SecondOwnerLastName,
  SecondOwnerDob,
  SecondOwnerSSN,
  SecondOwnerHomeAddress,
  EquipmentName,
  EquipmentCost,
  Suffix,
  Ssn,
  ResidentialAddress,
  Living,
  EmploymentStatus,
  EmployerDetails,
  RetirementDetails,
  EmploymentDetails,
  EmployerAddress,
  AdditionalIncome,
  CoApplicant,
  PurchaseDetails,
  FinancingDetails,
  CoApplicantEmail,
  CoApplicantPhone,
  CoApplicantFirstName,
  CoApplicantMiddleName,
  CoApplicantLastName,
  CoApplicantDob,
  CoApplicantSsn,
  CoApplicantSuffix,
  CoApplicantResidentialAddress,
  CoApplicantLiving,
  CoApplicantEmploymentStatus,
  CoApplicantRetirementDetails,
  CoApplicantAdditionalIncome,
  CoApplicantEmployerAddress,
  CoApplicantEmploymentDetails,
  CoApplicantEmployerDetails,
};
function Component(props) {
  const DynamicComponent = components[props.component];
  if (!components[props.component]) {
    return <p>Component is not defined: {props.component}</p>;
  }

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <DynamicComponent {...props} />
    </React.Suspense>
  );
}

export { Component };
