export const QUESTIONS = "QUESTIONS";
export const QUESTION_BUSINESS = "QUESTION_BUSINESS";
export const QUESTION_PERSONAL = "QUESTION_PERSONAL";
export const REFRESH_APPLICATION_LINK = "REFRESH_APPLICATION_LINK";
export const INFORMATION_SLUG = "INFORMATION_SLUG";
export const APPLICATION_UUID = "APPLICATION_UUID";
export const USER_INFO = "USER_INFO";
export const PREQUALIFIED_OFFERS = "PREQUALIFIED_OFFERS";
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const REFRESH_PROFILE = "REFRESH_PROFILE";
export const SIGNUP_RES = "SIGNUP_RES";
export const REFRESH_APPLICATIONS = "REFRESH_APPLICATIONS";
export const IS_PERSONAL_FLOW = "PERSONAL_FLOW";
