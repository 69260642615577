import MainApp from "../components/mainpage/MainApp";
import MainApplicationLink from "../pages/mainApplicationLink";
import MainApproved from "../pages/mainApproved";
import MainContractIn from "../pages/mainContractIn";
import MainContractOut from "../pages/mainContractOut";
import MainFunded from "../pages/mainFunded";
import MainInreview from "../pages/mainInreview";
import MainNotCompleted from "../pages/mainNotCompleted";
import Mainprofile from "../pages/mainprofile";
import MainOfferpage from "../pages/mainOfferpage";
import MainDealPage from "../pages/mainDealPage";
import MainApplication from "../pages/mainApplication";
import MainSummary from "../pages/mainSummary";
import AdminPanel from "../pages/admin/AdminPanel";
import MainPaynetReport from "../pages/MainPaynetReport";
import MainArchived from "../pages/mainArchived";

const protectedRoutes = [
    {
        path: "/",
        element: <MainApp />
    },
    {
        path: "/profile",
        element: <Mainprofile />
    },
    {
        path: "/applications/not-completed",
        element: <MainNotCompleted />
    },
    {
        path: "/applications/in-review",
        element: <MainInreview />
    },
    {
        path: "/applications/approved",
        element: <MainApproved />
    },
    {
        path: "/applications/contract-out",
        element: <MainContractOut />
    },
    {
        path: "/applications/contract-in",
        element: <MainContractIn />
    },
    {
        path: "/applications/funded",
        element: <MainFunded />
    },
    {
        path: "/applications/archived/",
        element: <MainArchived />  
    },
    {
        path: "/application",
        element: <MainApplicationLink />
    },
    {
        path: "/offer",
        element: <MainOfferpage />
    },
    {
        path: "/applications/:slug",
        element: <MainDealPage />
    },
    {
        path: "/application/:slug/details",
        element: <MainApplication />
    },
    {
        path: ":status/:slug/summary",
        element: <MainSummary />
    },
    {
        path: ":status/:slug/paynet-report",
        element: <MainPaynetReport />
    },
    {
        path: "/applications/:slug/offers",
        element: <MainOfferpage />
    },
    {
        path: "/users",
        element: <AdminPanel />
    }
]
export default protectedRoutes;