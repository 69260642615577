import React, { useContext, useState } from 'react'
import '../../styles/dashboard.css';
import axios from '../../../api/axios';
import Divider from '@mui/material/Divider';
import transparent from '../../../Images/transparent.webp'
import { GlobalContext } from '../../../context/GlobalContext';
import { getBaseStyle, getHoverStyle } from '../../../utils/styles';

const PrequalifiedOffers = ({ setComponent }) => {
    const { state } = useContext(GlobalContext)
    const [selectedOffer, setSelectedOffer] = useState(null)
    const [prequalifiedOffers] = useState(state?.prequalifiedOffers)
    const [buttonHover, setButtonHover] = useState(0)
    const [buttonHover2, setButtonHover2] = useState(false)
    const baseColor = state?.user?.themeColor;
    const baseStyle = getBaseStyle(baseColor);
    const hoverStyle = getHoverStyle(baseColor);

    const handleSubmit = async () => {
        if(!selectedOffer){
            alert("please select an offer first!")
            return
        }
        let body = {
            application: state?.applicationUUID,
            selected_offer: selectedOffer
        }
        axios.put("lender/list/deal-details/", body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true
        }).then(res => {
            setComponent("Information")
        }).catch(err => {
            console.log(err?.response)
        })
        
    }
    return (
        <div className='prequalified mt-3 mb-4'>
            <div className='d-flex justify-content-between'>
                <h5 className=''>Your prequalified offers:</h5>
                <button
                    onMouseEnter={() => setButtonHover2(true)}
                    onMouseLeave={() => setButtonHover2(false)}
                    style={state?.user?.themeColor === "" ? {} : buttonHover2 ? hoverStyle : baseStyle}
                    className='btn btn-primary' onClick={handleSubmit}>Next</button>
            </div>
            <div className='mt-3'>
                <div className='row mob-cards-gap'>
                    {prequalifiedOffers?.map((elem, index) => {
                        return (
                            <div className='col-lg-4 col-md-4 mb-4' key={index}>
                                <div className="card">
                                    <div className="card-body main-card b-color" onClick={() => setSelectedOffer(elem?.id)}
                                        style={{ backgroundColor: selectedOffer === index + 1 ? "rgba(229, 244, 255, 1)" : "white" }} >
                                        {/* <div className='d-flex justify-content-end'>
                                            <h5 className="card-title">Program 1</h5>
                                        </div> */}
                                        <div className='d-flex justify-content-end mb-3'>
                                            <img src={transparent} className='dark-img' alt="logo" />
                                        </div>

                                        <div className='tiers'>
                                            {/* <h5 className='cards-heading'>
                                                Tier: 2
                                            </h5> */}
                                            <Divider className='divider' />
                                            <p className='para mt-1'>Monthly Payment <span className='values'>{elem?.monthly_payment}</span></p>
                                            <Divider className='divider' />
                                            <p className='para mt-1'>Financing Amount <span className='values'>{elem?.financing_amount}</span></p>
                                            <Divider className='divider' />
                                            <p className='para mt-1'>Total Down Payment <span className='values'>{elem?.total_down_payment}</span></p>
                                            <Divider className='divider' />
                                            <p className='para mt-2 app-only'>Submission requirements: App only</p>
                                            <div className='tier-btn'>
                                                <button
                                                    type="submit"
                                                    className={`t-btn values`}
                                                    onMouseEnter={() => setButtonHover(index + 1)}
                                                    onMouseLeave={() => setButtonHover(0)}
                                                    style={state?.user?.themeColor === "" ?
                                                        { backgroundColor: selectedOffer === elem?.id ? "rgba(0, 148, 255, 0.1)" : "", color: selectedOffer === elem?.id ? "rgba(0, 148, 255, 1)" : "" }
                                                        : selectedOffer === elem?.id ? { backgroundColor: "rgba(0, 148, 255, 0.1)", color: baseColor } : buttonHover === index + 1 ? hoverStyle : baseStyle}
                                                >
                                                    {selectedOffer === elem?.id ? "selected" : "select"}
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>

            </div>

        </div>
    )
}

export default PrequalifiedOffers