import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponent = ({ activePage, pageCount, onPageChange }) => {
    const items = [];
    for (let number = 1; number <= pageCount; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === activePage}
                onClick={() => onPageChange(number)}
            >
                {number}
            </Pagination.Item>
        );
    }
    return (
        <div>

            <Pagination>
                <Pagination.Prev onClick={() => onPageChange(activePage - 1)} disabled={activePage === 1} />
                {items}
                <Pagination.Next onClick={() => onPageChange(activePage + 1)} disabled={pageCount === activePage} />
            </Pagination>
        </div>
    );
};

export default PaginationComponent;
