import React, { useState } from 'react';

function AddUser({ addUser }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation logic can be added here

    // Create a new user object
    const user = { email, password };

    // Pass the user object to the parent component for handling
    addUser(user);

    // Reset form fields
    setEmail('');
    setPassword('');
  };

  return (
    <div>
      <h2>Add User</h2>
      <form onSubmit={handleSubmit}>
        <label>Email:
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <label>Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <button type="submit">Add User</button>
      </form>
    </div>
  );
}

export default AddUser;