import React, { useState } from "react";
import "../../styles/question.css";
import { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { getBaseStyle, getHoverStyle } from "../../../utils/styles";

const Filterbtn = ({ filters, setFilters }) => {
  const [buttonHover1, setButtonHover1] = useState(false);
  const [buttonHover2, setButtonHover2] = useState(false);
  const { state } = useContext(GlobalContext);
  const baseColor = state?.user?.themeColor;
  const baseStyle = getBaseStyle(baseColor);
  const hoverStyle = getHoverStyle(baseColor);

  return (
    <div className="toggle-button-filter-container">
      <button
        onMouseEnter={() => setButtonHover1(true)}
        onMouseLeave={() => setButtonHover1(false)}
        style={
          state?.user?.themeColor === ""
            ? {}
            : filters === "My Apps"
            ? buttonHover1
              ? hoverStyle
              : baseStyle
            : {}
        }
        className={`toggle-button2 ${filters === "My Apps" ? "active" : ""}`}
        onClick={() => {
          setFilters("My Apps");
        }}
      >
        My Apps
      </button>
      <button
        onMouseEnter={() => setButtonHover2(true)}
        onMouseLeave={() => setButtonHover2(false)}
        style={
          state?.user?.themeColor === ""
            ? {}
            : filters === "All Apps"
            ? buttonHover1
              ? hoverStyle
              : baseStyle
            : {}
        }
        className={`toggle-button2 ${filters === "All Apps" ? "active" : ""}`}
        onClick={() => {
          setFilters("All Apps");
        }}
      >
        All Apps
      </button>
    </div>
  );
};

export default Filterbtn;
