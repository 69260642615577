import React from 'react'
import Sidebar from '../components/mainpage/subcomponent/Sidebar'
import '../components/styles/dashboard.css'
import Funded from '../components/mainpage/subcomponent/funded'

const MainFunded = () => {
    return (
        <div>
            <Sidebar />
            <div className='container-fluid'>
                <Funded />
            </div>
        </div>
    )
}

export default MainFunded