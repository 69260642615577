import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axios from "../../../api/axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
const cookies = new Cookies();

const AddNewUser = ({ handleCloseModal }) => {
  const [showAlert, setShowAlert] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const emailRef = useRef();
  const usernameRef = useRef();

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen2(false);
  };

  const handleSignup = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(true);
      setShowAlert("");
      setErrMsg("");
      setShowLoader(true);
      let body = {
        username,
        email,
        password,
      };
      axios
        .post("lender/admin/staff-user/", body, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${cookies.get("access")}`,
          },
          withCredentials: true,
        })
        .then((res) => {
          setTimeout(() => {
            handleCloseModal();
          }, 1000);
          setShowAlert(res.data?.Success);
          setOpen2(true);
          setEmail("");
          setUsername("");
          setPassword("");
          setValidated(false);
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
          if (!err?.response) {
            setErrMsg("No Server Response");
            setOpen(true);
          } else if (err.response?.status === 400) {
            if (err.response?.data?.email) {
              setErrMsg(err.response?.data?.email);
              setOpen(true);
            } else if (err.response?.data?.username) {
              setErrMsg(err.response?.data?.username);
              setOpen(true);
            }
          } else if (err.response?.status === 401) {
            window.localStorage.setItem("isLoggedIn", "false");
            navigate("/login");
          } else {
            setErrMsg(err.response?.data?.Error);
            setOpen(true);
          }
        });
    }
  };

  return (
    <>
      {errMsg && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseErrorSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={handleCloseErrorSnackbar}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errMsg}
          </Alert>
        </Snackbar>
      )}
      {showAlert && (
        <Snackbar
          open={open2}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={handleCloseSuccessSnackbar}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {showAlert}
          </Alert>
        </Snackbar>
      )}
      <div className="container mt-2 p-lg-5 p-md-5 p-4">
        <Form
          noValidate
          validated={validated}
          className=""
          onSubmit={handleSignup}
        >
          <h4 className="mb-4">Add New User</h4>
          <Form.Group
            className="mb-2 mt-1"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="">Email:</Form.Label>
            <Form.Control
              ref={emailRef}
              type="email"
              autoComplete="off"
              className="form-control mb-1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              aria-describedby="uidnote"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-2 mt-1"
            controlId="exampleForm.ControlInput2"
          >
            <Form.Label className="">Username:</Form.Label>
            <Form.Control
              type="username"
              ref={usernameRef}
              autoComplete="off"
              className="form-control mb-1"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              aria-describedby="uidnote"
              pattern={"^.{4,}$"}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid username.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="exampleForm.ControlInput3">
            <Form.Label className="" style={{ textAlign: "left" }}>
              Password:
            </Form.Label>
            <Form.Control
              type="password"
              className="form-control mb-1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              aria-describedby="pwdnote"
              pattern={"^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$"}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid password.
            </Form.Control.Feedback>
          </Form.Group>
          <div className="text-center">
            <button className="btn btn-dark  px-5" type="submit">
              {showLoader ? (
                <Spinner style={{ width: "1.4rem", height: "1.4rem" }} />
              ) : (
                "Add User"
              )}
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddNewUser;
