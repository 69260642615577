import React, { useEffect, useState } from 'react'
import '../../components/styles/dashboard.css';
import { CiSearch } from 'react-icons/ci';
import axios from '../../api/axios';
import Cookies from 'universal-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import QueryString from 'query-string';
import LoadingBar from 'react-top-loading-bar'
import image from '../../Images/nouser2.png'
import UsersData from '../../components/mainpage/subcomponent/usersData';
import AddUserModal from '../../utils/modals/AddUserModal';
import DeleteModal from '../../utils/modals/DeleteModal';
const cookies = new Cookies();


const Users = () => {
    const [progress, setProgress] = useState(30);
    const [showPage, setShowPage] = useState(false);
    const location = useLocation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [staffData, setStaffData] = useState([]);
    const [emailToDelete, setEmailToDelete] = useState('');
    const [usernameToDelete, setUsernameToDelete] = useState('');
    const search = QueryString.parse(location.search)?.search;
    const [paramSearch, setParamSearch] = useState(true);
    const [searchString, setSearchString] = useState(search ? search : '');
    const navigate = useNavigate();
    const updateQueryParams = (newParams) => {
        const currentParams = QueryString.parse(window.location.search);

        if (!newParams.hasOwnProperty('search')) {
            delete currentParams.search;
        }

        const updatedParams = { ...currentParams, ...newParams };

        const updatedQueryString = QueryString.stringify(updatedParams);

        // Update the URL with the new query string
        window.history.pushState({}, '', `?${updatedQueryString}`);

        // You can also use React Router navigation if you're using it
        // router.navigate(`?${updatedQueryString}`);
    };
    useEffect(() => {
        if (!showModal) {
            setRefresh(!refresh)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal])
    useEffect(() => {
        if (!showDeleteModal) {
            setRefresh(!refresh)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDeleteModal])
    const GetStaffData = () => {
        axios.get(`lender/admin/list/staff-users/?search=${searchString}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${cookies.get('access')}`
            },
            withCredentials: true
        }).then(res => {
            setProgress(100)
            setShowPage(true)
            setStaffData(res?.data?.staff_users)
            if (searchString !== "") {
                updateQueryParams({ search: searchString });
            } else {
                updateQueryParams({});
            }
        }).catch(err => {
            if (err?.response?.status === 401) {
                console.log(err);
                window.localStorage.setItem("isLoggedIn", "false");
                navigate("/login");
            } else if (err?.response?.status === 403) {
                navigate("/");
            }
        })
    }
    useEffect(() => {
        if (searchString === "") {
            GetStaffData();
            setParamSearch(false);
        } else if (search !== "" && paramSearch) {
            GetStaffData();
            setParamSearch(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString, refresh])
    const handleDeleteUser = (email) => {
        axios.delete(`lender/admin/staff-user/?email=${email}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${cookies.get('access')}`
            },
            withCredentials: true
        }).then(res => {
            console.log('res', res)
            setShowDeleteModal(false)
        }).catch(err => {
            console.log('err', err)
        })
    }
    function HandleKeyDown(e) {
        if (e.key === "Enter") {
            GetStaffData();
        }
    }
    return (
        <>
            <AddUserModal showModal={showModal} setShowModal={setShowModal} />
            <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} usernameToDelete={usernameToDelete} itemId={emailToDelete} deleteFunction={handleDeleteUser} />
            <LoadingBar
                color="rgba(0, 148, 255, 1)"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            {showPage && (<div className='row mt-4 mb-4'>
                <div className='col-lg-12 col-md-12'>
                    <div className='colorr shadow p-3 rounded-4 notCompleted-height'>
                        <div className='app-serach-field'>
                            <div>
                                <h4>Users</h4>
                            </div>
                            <div className='ms-auto'>
                                <div className='icon-field' onKeyDown={HandleKeyDown}>
                                    <CiSearch className='ms-2 icon-search' />
                                    <input
                                        className='search-here input-filed search-bar-field'
                                        type='text'
                                        name={searchString}
                                        placeholder='Search here...'
                                        value={searchString}
                                        onChange={(e) => {
                                            setSearchString(e.target.value)
                                        }}
                                    />
                                    <input type='button' onClick={GetStaffData} className='search-bar-btn' name='' value="Search" />
                                </div>
                            </div>
                        </div>

                        <div className='row mt-3'>

                            <div className='col-lg-12 col-md-12'>
                                <button className="btn btn-dark generate-link-btn" onClick={() => setShowModal(true)} style={{ borderRadius: "2rem", color: "white", fontWeight: "600" }}>Add new user</button>

                                <div className="App mt-3">

                                    {staffData?.length > 0 ?
                                        <UsersData data2={staffData} setShowModal={setShowDeleteModal} setEmailToDelete={setEmailToDelete} setUsernameToDelete={setUsernameToDelete} />
                                        :
                                        <div className='no-data'>
                                            <img src={image} alt="logo" className='data-display' style={{width:window.screen.width<500?"25%":"6%"}} />
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            )}
        </>
    )
}

export default Users