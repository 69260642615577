import React, { useEffect, useRef, useState } from "react";
import "../../styles/dashboard.css";
import "../../styles/printPdf.css";
import { PiPrinterLight } from "react-icons/pi";
import { Form, FormLabel } from "react-bootstrap";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from "../../../api/axios";
import Cookies from "universal-cookie";
import { useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { getBaseStyle, getHoverStyle } from "../../../utils/styles";
import ApplicationPdf from "./applicationPdf";
const cookies = new Cookies();
const Applicationsignature = () => {
  const { slug } = useParams();
  const [applicationDetails, setApplicationDetails] = useState();
  const componentRef = useRef();
  const [buttonHover, setButtonHover] = useState(false);
  const { state } = useContext(GlobalContext);
  const baseColor = state?.user?.themeColor;
  const baseStyle = getBaseStyle(baseColor);
  const hoverStyle = getHoverStyle(baseColor);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    axios
      .get(`lender/list-application/?application_uuid=${slug}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.get("access")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        setApplicationDetails(res.data);
      })
      .catch((err) => {});
  }, []);
  function formatNumber(sanitizedValue) {
    let formatted = "";
    for (let i = 0; i < sanitizedValue?.length; i++) {
      if (i === 2 || i === 5 || i === 8) {
        formatted += `-${sanitizedValue[i]}`;
      } else {
        formatted += sanitizedValue[i];
      }
    }
    return formatted;
  }
  function formatSSN(sanitizedValue) {
    let formatted = "";
    for (let i = 0; i < sanitizedValue?.length; i++) {
      if (i === 3 || i === 5) {
        formatted += `-${sanitizedValue[i]}`;
      } else {
        formatted += sanitizedValue[i];
      }
    }
    return formatted;
  }
  function foramtAddress(address) {
    return `${address?.house_number ?? ""} ${address?.street_name ?? ""}, ${
      address?.city ?? ""
    }, ${address?.state ?? ""} ${address?.zip_code ?? ""}, ${
      address?.country ?? ""
    }`;
  }

  const content1 = (
    <>
      <div className="company-info-app">
        <div>
          <h4>Application</h4>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-lg-4 col-md-4">
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>First Name:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.first_name}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Phone:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={formatNumber(applicationDetails?.phone_no)}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Company Name:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.company_name ?? ""}
                readOnly
              />
            </Form.Group>
          </div>

          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>#of Years in Business:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.business_duration}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Tax Id: (Optional)</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.tax_id ?? ""}
                readOnly
              />
            </Form.Group>
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Last Name:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.last_name}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Home Address:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={
                  applicationDetails?.home_address
                    ? foramtAddress(applicationDetails?.home_address)
                    : ""
                }
                readOnly
              />
            </Form.Group>
          </div>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Company Address:</Form.Label>
            <Form.Control
              type="text"
              className="input-f-color"
              value={
                applicationDetails?.company_address
                  ? foramtAddress(applicationDetails?.company_address)
                  : ""
              }
              readOnly
            />
          </Form.Group>

          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>% Ownership:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.ownership_percentage}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>SSN:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={formatSSN(applicationDetails?.ssn_number)}
                readOnly
              />
            </Form.Group>
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.email}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>DOB:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.date_of_birth}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Type of Business:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.entity_type ?? ""}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Annual Revenue:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={`$${applicationDetails?.annual_business_revenue?.toLocaleString()}`}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Purchase:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.target_purchase}
                readOnly
              />
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  );
  const content2 = (
    <>
      <div>
        <h5 className="mt-2">
          INFORMATION ON OFFICERS, PARTNERS OR GUARANTORS
        </h5>
      </div>
      <div className="row mt-3">
        <h6>FIRST OWNER </h6>
        <div className="col-lg-3 col-md-3">
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>First Name:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.first_name}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Home Phone:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.phone_no}
              />
            </Form.Group>
          </div>

          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name:</Form.Label>
              <Form.Control type="text" className="input-f-color" readOnly />
            </Form.Group>
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Last Name:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.last_name}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Home Address:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={
                  applicationDetails?.home_address
                    ? foramtAddress(applicationDetails?.home_address)
                    : ""
                }
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>% Ownership:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.ownership_percentage}
              />
            </Form.Group>
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.email}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>City/State:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={
                  applicationDetails?.home_address
                    ? `${applicationDetails.home_address.city || ""} / ${
                        applicationDetails.home_address.state || ""
                      }`
                    : ""
                }
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>SSN:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.ssn_number}
              />
            </Form.Group>
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title:</Form.Label>
              <Form.Control type="text" className="input-f-color" />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Zip:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={
                  applicationDetails?.home_address
                    ? applicationDetails?.home_address.zip_code
                    : ""
                }
              />
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <h6>SECOND OWNER </h6>
        <div className="col-lg-3 col-md-3">
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>First Name:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.second_owner_first_name}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Home Phone:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.second_owner_phone_no}
                readOnly
              />
            </Form.Group>
          </div>

          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name:</Form.Label>
              <Form.Control type="text" className="input-f-color" readOnly />
            </Form.Group>
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Last Name:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.second_owner_last_name}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Home Address:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={
                  applicationDetails?.second_owner_home_address
                    ? foramtAddress(
                        applicationDetails?.second_owner_home_address
                      )
                    : ""
                }
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>% Ownership:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.second_owner_ownership_percentage}
              />
            </Form.Group>
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.second_owner_email}
                readOnly
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>City/State:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={
                  applicationDetails?.second_owner_home_address
                    ? `${
                        applicationDetails.second_owner_home_address.city || ""
                      } / ${
                        applicationDetails.second_owner_home_address.state || ""
                      }`
                    : ""
                }
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>SSN:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={applicationDetails?.second_owner_ssn_number}
              />
            </Form.Group>
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title:</Form.Label>
              <Form.Control type="text" className="input-f-color" />
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Zip:</Form.Label>
              <Form.Control
                type="text"
                className="input-f-color"
                value={
                  applicationDetails?.second_owner_home_address
                    ? applicationDetails?.second_owner_home_address.zip_code
                    : ""
                }
              />
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  );
  const content3 = (
    <>
      <div>
        <h5>VENDOR INFORMATION</h5>
      </div>
      <div className="mt-3">
        <h5>EQUIPMENT DESCRIPTION (MAKE, MODEL, YEAR)</h5>
      </div>
      <div className="mt-3">
        <h5>EQUIPMENT COST</h5>
      </div>
    </>
  );
  const content4 = (
    <>
      <div>
        <div className="mt-3">
          <h5>DECLARATION</h5>
        </div>
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="declear">
              <p className="p-3 declare-font">
                The individual(s) signing below and any person for which
                information is provided affirmatively consents and agrees: (i)
                that we hereby represent that I/we am/are authorized to provide
                all information submitted and hereby provide my/our written
                instructions to Mazo Capital Solutions and its affiliates,
                assigns, successors and lending partners (collectively "Mazo
                Capital Solutions") to obtain information concerning the
                applicant and me/us from any company, entity, organization,
                government agency (including law enforcement) or business,
                including my/our consumer and business credit reports),
                employment information, business dealings and trade references;
                (lil that Mazo Capital Solutions may use and share any submitted
                and gathered information from or about the applicant and its
                owners or principals with lenders for consideration for a credit
                product, including any loan or lease or to finance the purchase
                of equipment; (ili) that all information provided is true,
                accurate and complete; (iv) that Mazo Capital Solutions may
                initially and subsequently obtain information from my/our
                consumer or business credit reports) and otherwise in order to:
                [al confirm my/our identity; b) avoid fraudulent transactions in
                my/our name; (c) to evaluate my/our and any applicant's initial
                and continuing creditworthiness; (d to update, renew, confirm,
                extend, supplement, enforce and collect on any credit
                transaction resulting from this application; and (e) that such
                inquiries may appear on my/our credit reports; (v) we waive any
                right or claim under the Fair Credit Reporting Act, the Drivers'
                Policy Protection Act and the Gramm-Leach-sliley Act arising in
                the absence of this continuing consent; and (vi) to receive any
                notices required by law electronically to the electronic mail
                addresses) and or mobile telephone numbers provided. The Federal
                Equal Credit Opportunity Act prohibits creditors from
                discriminating against credit applicants on the basis of race,
                color, religion, national origin, sex, marital status or age
                (provided the applicant has the capacity to enter into the
                binding contract); because all or part of the applicant's income
                derives from any public assistance program; or because the
                applicant has in good faith exercised any right under the
                Consumer Credit Protection Act. If for any reason your
                application for business credit is denied, you have the right to
                a written statement of the specific reasons for the denial. To
                obtain the statement, please write to Mazo Capital Solutions,
                300 Spectrum Center Drive, Suite 1070, Irvine, CA 92618 within
                60 days from the date you are notified of our decision. We will
                send you a written statement of reasons for the denial of credit
                within 30 days of receiving your request for the statement. If
                you have provided Mazo Capital Solutions with a mobile telephone
                number, above or otherwise, you expressly consent to receive SM5
                text messages related to your application, any resulting
                transaction as well as promotional and marketing messages. You
                may opt out at any time by responding to any message we send
                with STOP, UNSUBSCRIBE, CANCEL, END or QUIT, after which we may
                send you a message confirming you have opted out. You may opt in
                again by replying to any previous message with START
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <>
      <div className="row mt-4 mb-4">
        <div className="col-lg-12 col-md-12">
          <div className="colorr shadow p-3 rounded-4">
            <div className="app-btn ms-auto text-end">
              <button
                onMouseEnter={() => setButtonHover(true)}
                onMouseLeave={() => setButtonHover(false)}
                style={
                  state?.user?.role !== "Staff"
                    ? {
                        backgroundColor: "rgba(0, 148, 255, 1)",
                        color: "rgba(255, 255, 255, 1)",
                      }
                    : buttonHover
                    ? hoverStyle
                    : baseStyle
                }
                type="button"
                className="btn print-btn"
                onClick={handlePrint}
              >
                <PiPrinterLight /> <span className="print-font"> Print</span>
              </button>
            </div>
            <div style={{ display: "none" }}>
              <div ref={componentRef}>
                <ApplicationPdf applicationDetails={applicationDetails} />
              </div>
            </div>
            <div>
              {content1}
              {content2}
              {content3}
              <div className="page-break" />
              {content4}
              <div className="row mt-3">
                <Form.Group
                  className="mb-3 col-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Created at:</Form.Label>
                  <Form.Control
                    type="text"
                    className="input-f-color"
                    value={applicationDetails?.created_at}
                    readOnly
                  />
                </Form.Group>
              </div>
              <div className="text-area">
                <Form.Group
                  className="mb-3 text-area"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Signature</Form.Label>
                  <div className="color-filed">
                    <img
                      src={applicationDetails?.signature}
                      alt="Unavailable"
                      style={{ border: "1px solid black", width: "445PX" }}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Applicationsignature;
