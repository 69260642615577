import { useState } from 'react'
import { Form } from 'react-bootstrap';
import axios from '../../../api/axios';
import Spinner from 'react-bootstrap/Spinner';

const SendEmail = () => {
    const [showAlert, setShowAlert] = useState('')
    const [errMsg, setErrMsg] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [email, setEmail] = useState('');
    const [validated, setValidated] = useState(false);

    const handleSignup = (e) => {
        setShowAlert('')
        setErrMsg('')
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true)
        } else {
            setValidated(true)
            setShowLoader(true)
            let body = {
                email: email,
            }
            axios.post('auth/reset-password/', body)
                .then(res => {
                    setShowAlert("We have sent you an email to reset your password. Please check your inbox.")
                    setValidated(false)
                    setShowLoader(false)
                }).catch(err => {
                    setShowLoader(false)
                    if (!err?.response) {
                        setErrMsg('No Server Response');
                    } else if (err.response?.status === 400) {
                        setErrMsg(err.response?.data?.Error);
                    } else {
                        setErrMsg(err.response?.data?.Error);
                    }
                })
        }

    }
    return (
        <>
            <div className="container mt-2 p-lg-5 p-md-5 p-4" >
                <Form noValidate validated={validated} className='text-center' onSubmit={handleSignup}>
                    <div className={errMsg ? "alert alert-danger alert-dismissible fade show" : "offscreen"}>
                        {errMsg}
                        <button type="button" className={errMsg ? "btn-close" : "d-none"} onClick={() => setErrMsg('')}></button>
                    </div>
                    <div className={showAlert ? "alert alert-info alert-dismissible fade show" : "offscreen"}>
                        {showAlert}
                        <button type="button" className={showAlert ? "btn-close" : "d-none"} onClick={() => setShowAlert('')}></button>
                    </div>
                    <h4 className='mb-4'>Enter your email address to reset your password.</h4>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1">
                        <Form.Label className='Email' style={{ float: "left" }}>Email:</Form.Label>
                        <Form.Control
                            type="email"
                            autoComplete="off"
                            className="form-control mb-3"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            aria-describedby="uidnote"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className="text-center">
                        <button className="btn btn-dark  px-5" type="submit">
                            {showLoader ? <Spinner style={{ width: "1.4rem", height: "1.4rem" }} /> : "Verify Email"}</button>
                    </div>
                </Form>
            </div>
        </>
    )
}
export default SendEmail;