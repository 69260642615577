import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import { ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import image from '../../Images/Frame-31.webp';
import { BiUpload } from 'react-icons/bi';
import axios from '../../api/axios';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
const cookies = new Cookies();
const UploadFilesModal = ({ showModal, setShowModal, uploadedFiles, setRefresh, dealId, refresh,setProgress }) => {
    const [radioChoice, setRadioChoice] = useState("uploaded")
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showAlert, setShowAlert] = useState('')
    const [errMsg, setErrMsg] = useState('');
    const radios = [
        { name: 'Uploaded', value: 'uploaded' },
        { name: 'Upload', value: 'upload' },
    ];
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleClose2 = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
        setOpen2(false)
    };

    const handleFileChange = (event) => {
        setShowAlert("")
        const files = event.target.files;
        setSelectedFiles([...selectedFiles, ...files]);
    };
    const handleClose = () => {
        setRadioChoice("uploaded");
        setShowModal(false);
    };
    function RemoveFile(fileName) {
        const arr = [...selectedFiles]
        const newFiles = arr.filter(el => el?.name !== fileName)
        setSelectedFiles(newFiles)

    }
    function handleSaveFiles() {
        let data = new FormData();
        selectedFiles?.forEach((file) => {
            data.append('files', file);
        });
        data.append('deal_id', dealId)
        setProgress(30)
        axios.post("lender/deal/upload-docs/", data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${cookies.get("access")}`,
            },
            withCredentials: true,
        }).then(res => {
            console.log(res)
            setRefresh(!refresh)
            setProgress(100)
            setSelectedFiles([])
            setShowAlert(res?.data?.Success);
            setOpen2(true)
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <>
            {
                errMsg && (
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Alert
                            onClose={handleClose2}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {errMsg}
                        </Alert>
                    </Snackbar>
                )
            }
            {
                showAlert && (
                    <Snackbar
                        open={open2}
                        autoHideDuration={6000}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Alert
                            onClose={handleClose2}
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {showAlert}
                        </Alert>
                    </Snackbar>
                )
            }
            <Modal show={showModal} onHide={handleClose}
                aria-labelledby="example-custom-modal-styling-title"
                dialogClassName="modal-width"
                centered
                animation={true}>
                <Modal.Body className='m-2'>
                    <div style={{ float: "right", fontSize: '13px', marginTop: "0.4rem" }}><CloseButton onClick={handleClose} variant="black" /></div>
                    {/* <div className={errMsg ? "alert alert-danger alert-dismissible fade show" : "offscreen"}>
                        {errMsg}
                        <button type="button" className={errMsg ? "btn-close" : "d-none"} onClick={() => setErrMsg('')}></button>
                    </div>
                    <div className={showAlert ? "alert alert-info alert-dismissible fade show" : "offscreen"}>
                        {showAlert}
                        <button type="button" className={showAlert ? "btn-close" : "d-none"} onClick={() => setShowAlert('')}></button>
                    </div> */}
                    <div className='d-flex mt-4 mb-3 justify-content-center'>
                        <ButtonGroup className="mb-2">
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    variant="outline-dark"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioChoice === radio.value}
                                    onChange={(e) => setRadioChoice(e.currentTarget.value)}
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </div>
                    {radioChoice === "uploaded" ? <><h4>Uploaded Files</h4>
                        {uploadedFiles?.length < 1 ?
                            <div className='d-flex justify-content-center m-5'>
                                <img src={image} alt="logo" width="50" />
                            </div>
                            :
                            <div className='p-3'>
                                <ul>
                                    {uploadedFiles?.map((file, index) => (
                                        <div className='d-flex' key={index}>
                                            <Link to={file?.link} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}><li className='me-4' >{file?.name}</li></Link>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        }
                    </> :
                        <>
                            <h4>Upload Files</h4>
                            <div className='p-2'>
                                <button
                                    type="submit"
                                    className="upload-btn p-2 d-flex justify-content-center"
                                    onClick={() => document.querySelector('#hidden-file-input').click()}
                                >
                                    <BiUpload className='upload-icon mb-1' /> <span>Upload</span>
                                    <Form.Control
                                        id="hidden-file-input"
                                        className='d-none'
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                </button>
                            </div>
                            {selectedFiles?.length > 0 && <div className='p-3'>
                                <h5>Uploaded Files:</h5>
                                <ul>
                                    {selectedFiles.map((file, index) => (
                                        <div className='d-flex' key={index}>
                                            <li className='me-4' >{file?.name}</li>
                                            <div style={{ float: "right", fontSize: '12px', marginTop: "4px" }}><CloseButton onClick={() => { RemoveFile(file?.name) }} variant="black" /></div>
                                        </div>
                                    ))}
                                </ul>
                                <button
                                    onClick={handleSaveFiles}
                                    className="upload-btn p-2 d-flex justify-content-center mt-5"
                                >Save
                                </button>
                            </div>}
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}

export default UploadFilesModal