import React from 'react'
import '../../styles/dashboard.css';
import { Table } from 'react-bootstrap';

const Dealtable = ({ data3 }) => {
    return (
        <>
            <Table className='table-backgorund'>
                <thead className='table-head'>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>FICO Score</th>
                        <th>Highest Comparable credit</th>
                        <th>Number of late payments</th>
                        <th>Collections</th>
                        <th>Previous Bankruptcies</th>
                    </tr>
                </thead>
                <tbody>
                    <div style={{ marginBottom: "0.5rem" }}></div>
                    <tr className='deal'>
                        <td>{data3?.first_name}</td>
                        <td>{data3?.last_name}</td>
                        <td>{data3?.fico_score}</td>
                        <td>{data3?.high_comp_24_months ?? "tbd"}</td>
                        <td>{data3?.number_of_late_payments}</td>
                        <td>{data3?.collections_non_medical}</td>
                        <td>{data3?.last_bankruptcy_status ?? "tbd"}</td>
                    </tr>


                </tbody>
            </Table>
        </>
    )
}

export default Dealtable