import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import { Form } from 'react-bootstrap';
import { LuSend } from 'react-icons/lu';
import axios from '../../api/axios';
import Cookies from 'universal-cookie';
import Spinner from 'react-bootstrap/Spinner';
import { GlobalContext } from '../../context/GlobalContext';
import { getBaseStyle, getHoverStyle } from '../styles';
const cookies = new Cookies();
const SendApplicationLinkModal = ({ showModal, setShowModal, applicationLink }) => {
    const [showAlert, setShowAlert] = useState('')
    const [errMsg, setErrMsg] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [email, setEmail] = useState("");
    const [radioChoice, setRadioChoice] = useState("email")
    const [phone, setPhone] = useState("+1");
    const [validated, setValidated] = useState(false);
    const inputRef = useRef();
    const [buttonHover, setButtonHover] = useState(false)
    const { state, dispatch } = useContext(GlobalContext)
    const baseColor = state?.user?.themeColor;
    const baseStyle = getBaseStyle(baseColor);
    const hoverStyle = getHoverStyle(baseColor);
    function formatNumber(sanitizedValue) {
        let formatted = '';
        for (let i = 0; i < sanitizedValue.length; i++) {
            if (i === 2 || i === 5 || i === 8) {
                formatted += `-${sanitizedValue[i]}`;
            } else {
                formatted += sanitizedValue[i];
            }
        }
        return formatted;
    }
    const handleOnChange = (e) => {
        const value = e.target.value;

        const sanitizedValue = value.replace(/[^A-Za-z0-9+]/g, '');
        if (sanitizedValue.length < 13) {
            let formatted = formatNumber(sanitizedValue)
            setPhone(formatted);
        }
    };
    useEffect(() => {
        setValidated(false);
        setEmail("")
        setPhone("+1")
    }, [radioChoice])
    const handleClose = () => {
        setValidated(false);
        setEmail("");
        setPhone("+1");
        setRadioChoice("email");
        setShowModal(false);
    };
    const handleSend = (e) => {
        setErrMsg("");
        setShowAlert("");
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true)
        } else {
            setValidated(true)
            setShowLoader(true)
            let body;
            if (radioChoice === "email") {
                body = {
                    platform: "email",
                    email: email,
                    application_uuid: applicationLink
                }
            } else {
                body = {
                    platform: "phone_number",
                    phone_number: phone?.replace(/-/g, ''),
                    application_uuid: applicationLink
                }
            }

            axios.post("lender/send/application-link/", body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }).then(res => {
                if (res.data.Success) {
                    let bodyToUpdate;
                    if (radioChoice === "email") {
                        bodyToUpdate = {
                            recent_send_email: email,
                            application_uuid: applicationLink
                        };
                    } else {
                        bodyToUpdate = {
                            recent_send_phone_number: phone?.replace(/-/g, ''),
                            application_uuid: applicationLink
                        };
                    }

                    axios.put("lender/update/application-link/recent/", bodyToUpdate, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${cookies.get('access')}`
                        },
                        withCredentials: true
                    }).then(updateRes => {
                        dispatch({
                            type: 'REFRESH_APPLICATIONS',
                            payload: true
                        });
                    }).catch(updateErr => {
                        // Handle error for second request
                        console.log(updateErr)
                    });
                }
                setEmail("");
                setPhone("");
                setShowAlert(res?.data?.Success);
                setValidated(false);
                setShowLoader(false);
            }).catch(err => {
                setErrMsg("Server Error");
                setShowLoader(false);
                setValidated(false);
            });
        }
    };
    return (
        <>
            <Modal show={showModal} onHide={handleClose}
                aria-labelledby="example-custom-modal-styling-title"
                dialogClassName="modal-width"
                centered
                animation={true}>
                <Modal.Body className='m-2'>
                    <div style={{ float: "right", fontSize: '13px', marginTop: "0.4rem" }}><CloseButton onClick={handleClose} variant="black" /></div>
                    <h4>Sending application link to {radioChoice}</h4>
                    <div className={errMsg ? "alert alert-danger alert-dismissible fade show" : "offscreen"}>
                        {errMsg}
                        <button type="button" className={errMsg ? "btn-close" : "d-none"} onClick={() => setErrMsg('')}></button>
                    </div>
                    <div className={showAlert ? "alert alert-info alert-dismissible fade show" : "offscreen"}>
                        {showAlert}
                        <button type="button" className={showAlert ? "btn-close" : "d-none"} onClick={() => setShowAlert('')}></button>
                    </div>
                    <div className='d-flex mt-4 mb-3'>
                        <Form.Check
                            className='me-3'
                            type={"radio"}
                            label={`Email`}
                            defaultChecked={true}
                            name="group1"
                            defaultValue={"email"}
                            onChange={(e) => setRadioChoice(e.target.value)}
                            id={`disabled-default-1`}
                        />
                        <Form.Check
                            type={"radio"}
                            label={`Phone`}
                            onChange={(e) => setRadioChoice(e.target.value)}
                            defaultValue={"phone"}
                            name="group1"
                            id={`disabled-default-2`}
                        />
                    </div>
                    <Form noValidate validated={validated} onSubmit={handleSend}>
                        {radioChoice === "email" ?
                            <Form.Group className="color-filed" controlId="exampleForm.ControlInput1">
                                <Form.Label className='form-lable'>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    autoComplete="off"
                                    className="form-control"
                                    name="email"
                                    value={email}
                                    placeholder="name@example.com"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid email.
                                </Form.Control.Feedback>
                            </Form.Group>
                            :
                            <Form.Group className="color-filed" controlId="exampleForm.ControlInput2">
                                <Form.Label className='form-lable'>Phone number</Form.Label>
                                <Form.Control
                                    ref={inputRef}
                                    type="tel"
                                    placeholder="Enter Phone Number with (+1)"
                                    value={phone}
                                    onChange={handleOnChange}
                                    pattern="\+1-\d{3}-\d{3}-\d{4}"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Valid US phone number e.g.(+1-123-456-7890).
                                </Form.Control.Feedback>
                            </Form.Group>
                        }
                        <button
                            className='btn btn-primary float-end ms-2 mt-5 mb-1 px-3'
                            onMouseEnter={() => setButtonHover(true)}
                            onMouseLeave={() => setButtonHover(false)}
                            style={state?.user?.role !== "Staff" ? {} : buttonHover ? hoverStyle : baseStyle}>
                            {showLoader ? <Spinner style={{ width: "1.4rem", height: "1.4rem" }} /> : <><LuSend /> Send</>}</button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SendApplicationLinkModal